import axios from "axios";
import config from "../config";
const msGraphConfig = config.axios.microsoftGraph;

const getEmployees = async (config) => {
  const { token, select, filter } = config;
  return axios({
    ...msGraphConfig,
    url: `/users?${select ? `${select}` : ""}${filter ? `${filter}` : ""}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getEmployeeByPrincipalName = async (config) => {
  const { token, principalName } = config;
  return axios({
    ...msGraphConfig,
    url: `/users/${principalName}?$select=displayName,department,id,jobTitle,imAddresses,mail,givenName,surname,mobilePhone`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getEmployeeImageByPrincipalName = async (config) => {
  const { principalName, token } = config;
  return axios({
    ...msGraphConfig,
    //url: `/users/${principalName}/photo/$value`,
    url: `/users/${principalName}?$select=birthday`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  });
};

const getEmployeeLicenseDetailsById = async (employeeId, token) => {
  return axios({
    ...msGraphConfig,
    url: `/users/${employeeId}/licenseDetails`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getEmployeesByGroupId = async (config) => {
  const { token, groupId } = config;
  return axios({
    ...msGraphConfig,
    url: `/groups/${groupId}/members`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getManagerByPrincipalName = async (config) => {
  const { token, principalName } = config;
  return axios({
    ...msGraphConfig,
    url: `/users/${principalName}/manager`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getDirectReportsByPrincipalName = (config) => {
  const { token, principalName } = config;
  return axios({
    ...msGraphConfig,
    url: `/users/${principalName}/directReports`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateManagerById = (config) => {
  const { token, id, managerId } = config;
  return axios({
    ...msGraphConfig,
    method: "PUT",
    url: `/users/${id}/manager/$ref`,
    data: {
      "@odata.id": `https://graph.microsoft.com/v1.0/users/${managerId}`,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

const updateEmployeeDepartmentById = (config) => {
  const { token, id, departmentName } = config;
  return axios({
    ...msGraphConfig,
    method: "PATCH",
    url: `/users/${id}`,
    data: { department: departmentName },
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export default {
  getEmployees,
  getEmployeeImageByPrincipalName,
  getEmployeeLicenseDetailsById,
  getEmployeesByGroupId,
  getManagerByPrincipalName,
  getDirectReportsByPrincipalName,
  getEmployeeByPrincipalName,
  updateManagerById,
  updateEmployeeDepartmentById,
};

import React, { useContext, useEffect, useState } from "react";
import EmployeeCard from "../employee-card";
import DirectoryToolbar from "../directory-toolbar";
import { DirectoryContext } from "../../../../App";
import { ButtonGroup, Grid, Button } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import * as XLSX from "xlsx";

const buttonProps = {
  size: "small",
  sx: {
    textTransform: "capitalize",
    width: "100px",
  },
};

const companies = [
  {
    name: "Transblue",
    domain: "transblue",
  },
  {
    name: "Evergreen Brands",
    domain: "evergreenbrands",
  },
  {
    name: "Condo Shield",
    domain: "condoshield",
  },
  {
    name: "Honey 4 Hope",
    domain: "honey4hope",
  },
  {
    name: "mBridge",
    domain: "mbridge",
  },
];

const getCompany = (mail) => {
  let company = companies.find((c) => mail.toLowerCase().includes(c.domain));
  return company;
};

const DirectoryHome = (props) => {
  const directoryContext = useContext(DirectoryContext);
  const { employees } = directoryContext;
  const [filteredEmployees, setFilteredEmployees] = useState(employees);
  const [view, setView] = useState("chart");
  const [employeesWithManager, setEmployeesWithManager] = useState([]);

  useEffect(() => {
    let array = employees.map((e) => ({
      ...e,
      manager: employees.find((emp) => emp.directReports?.includes(e.id))
        ?.displayName,
    }));
    setEmployeesWithManager(array);
  });

  const columns = [
    {
      field: "displayName",
      headerName: "Name",
      flex: 0.25,
    },
    {
      field: "company",
      headerName: "Company",
      renderCell: (params) => getCompany(params.row.mail)?.name,
      flex: 0.25,
    },
    {
      field: "department",
      headerName: "Department",
      flex: 0.25,
    },
    {
      field: "manager",
      headerName: "Manager",
      flex: 0.25,
    },
    {
      field: "jobTitle",
      headerName: "Title",
      flex: 0.25,
    },

    {
      field: "businessPhone",
      headerName: "Business Phone",
      flex: 0.25,
      renderCell: (params) => params.row?.businessPhones?.[0],
    },
    {
      field: "mobilePhone",
      headerName: "Mobile Phone",
      flex: 0.25,
    },
    {
      field: "faxNumber",
      headerName: "Extension",
      flex: 0.25,
    },
  ];

  const exportEmployees = () => {
    let worksheet = employees.map((e) => ({
      Name: e.displayName,
      Company: getCompany(e.mail)?.name,
      Department: e.department,
      Manager: employees.find((emp) => emp.directReports?.includes(e.id))
        ?.displayName,
      Title: e.jobTitle,
      "Business Phone": e.businessPhones?.[0],
      "Mobile Phone": e.mobilePhone,
      Extension: e.faxNumber,
      Email: e.mail,
    }));

    console.log(worksheet);

    worksheet = XLSX.utils.json_to_sheet(worksheet);

    let workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Employees.xlsx");
  };

  return (
    <div className="directoryHome">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: { xs: "100vw" },
          alignItems: "center",
        }}
      >
        <h1>Transblue Directory</h1>
        <Box>
          <ButtonGroup>
            <Button
              variant={view === "chart" ? "contained" : "outlined"}
              {...buttonProps}
              onClick={() => setView("chart")}
            >
              Directory
            </Button>
            <Button
              variant={view === "list" ? "contained" : "outlined"}
              {...buttonProps}
              onClick={() => setView("list")}
            >
              List
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
      <DirectoryToolbar setFilteredEmployees={setFilteredEmployees} />
      {view === "chart" ? (
        <div className="employeeCards">
          {filteredEmployees.length > 0 ? (
            <Grid container spacing={4}>
              {filteredEmployees.map((employee) => {
                return (
                  <Grid item xs={12} lg={3} xl={2}>
                    <EmployeeCard
                      key={employee.mail}
                      employee={employee}
                      cardWidth={250}
                      cardMargin={20}
                      includeDepartment
                      includeLinks
                    />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <>Loading...</>
          )}
        </div>
      ) : (
        <Box
          sx={{
            width: "80vw",
            bgcolor: "white",
            height: "80vh",
            px: 2,
            pt: 2,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
            <Button
              onClick={exportEmployees}
              {...buttonProps}
              variant="contained"
            >
              Export
            </Button>
          </Box>
          <DataGrid
            columns={columns}
            rows={employeesWithManager}
            autoPageSize
            pagination
            density="compact"
          />
        </Box>
      )}
    </div>
  );
};

export default DirectoryHome;

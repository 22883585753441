import axios from 'axios'
import config from '../config'
const clubhouseServiceConfig = config.axios.clubhouseService

const getDepartments = async (config) => {
    const { clubhouseFnsToken } = config
    return await axios({
        ...clubhouseServiceConfig,
        url: '/departments',
        headers: { 
            Authorization: `Bearer ${clubhouseFnsToken}`
        }
    })
}

const deleteDepartment = async (config) => {
    const { clubhouseFnsToken, departmentId } = config
    return axios({
        ...clubhouseServiceConfig,
        method: 'delete',
        url: '/departments',
        params: { id: departmentId },
        headers: {
            Authorization: `Bearer ${clubhouseFnsToken}`
        }
    })
}

const updateDepartment = async (config) => {
    const { clubhouseFnsToken, updatedDepartment } = config
    return axios({
        ...clubhouseServiceConfig,
        method: 'put',
        url: '/departments',
        params: { id: updatedDepartment.id },
        data: updatedDepartment,
        headers: {
            Authorization: `Bearer ${clubhouseFnsToken}`
        }
    })
}

const createDepartment = async (config) => {
    const { clubhouseFnsToken, newDepartment } = config
    return axios({
        ...clubhouseServiceConfig,
        method: 'post',
        url: '/departments',
        data: newDepartment,
        headers: {
            Authorization: `Bearer ${clubhouseFnsToken}`
        }
    })
}

const getEvaluationsById = async (config) => {
    const { clubhouseFnsToken, employeeId } = config
    return await axios({
        ...clubhouseServiceConfig,
        method: 'get',
        url: '/getEvaluationsById',
        params: { employeeId },
        headers: { 
            Authorization: `Bearer ${clubhouseFnsToken}`
        }
    })
}

const postEvaluation = async (config) => {
    const { clubhouseFnsToken, evaluation } = config
    return axios({
        ...clubhouseServiceConfig,
        method: 'post',
        url: '/postEvaluation',
        data: evaluation,
        headers: {
            Authorization: `Bearer ${clubhouseFnsToken}`
        }
    })
}

const updateEvaluationById = async (config) => {
    const { clubhouseFnsToken, updatedEvaluation } = config
    return axios({
        ...clubhouseServiceConfig,
        method: 'put',
        url: '/putEvaluationById',
        data: updatedEvaluation,
        headers: {
            Authorization: `Bearer ${clubhouseFnsToken}`
        }
    })
}

const postRequest = (config) => {
    const { type, data, clubhouseFnsToken } = config
    return axios({
        ...clubhouseServiceConfig,
        method: 'post',
        url: '/postRequest',
        data: data,
        params: { type },
        headers: {
            Authorization: `Bearer ${clubhouseFnsToken}`
        }
    })
}

const getRequests = (config) => {
    const { clubhouseFnsToken, params } = config
    return axios({
        ...clubhouseServiceConfig,
        url: '/requests',
        params,
        headers: {
            Authorization: `Bearer ${clubhouseFnsToken}`
        }
    })
}

const updateRequest = (config) => {
    const { clubhouseFnsToken, params, requestToApprove } = config
    return axios({
        ...clubhouseServiceConfig,
        method: 'put',
        url: '/requests',
        params,
        data: requestToApprove,
        headers: {
            Authorization: `Bearer ${clubhouseFnsToken}`
        }
    })
}

export default { 
    getDepartments,
    deleteDepartment,
    updateDepartment,
    createDepartment,
    getEvaluationsById,
    postEvaluation,
    updateEvaluationById,
    postRequest,
    getRequests,
    updateRequest
}

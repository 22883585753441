import { Paper, Typography, Slide, Box, IconButton, Divider } from "@mui/material";
import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Clear } from "@mui/icons-material";

const columns = [
  {
    field: 'date',
    headerName: 'Date Submitted',
    flex: .5,
    renderCell: params => new Date(params.row._ts * 1000).toLocaleDateString(),
  },
  {
    field: 'items',
    headerName: 'Number of Items',
    renderCell: params => params.row?.rows?.length || 0,
    flex: 1
  },
  {
    field: 'attachments',
    headerName: 'Number of Attachments',
    flex: .5,
    renderCell: params => params.row?.attachments?.length || 0
  },
  {
    field: 'total',
    headerName: 'Total Amount',
    renderCell: (params) => `$${params.row?.total || 0}`
  }
]

function PreviousExpenseReports({ reports }) {
  console.log('reports', reports)
  const [activeReport, setActiveReport] = useState(null);
  const open = Boolean(activeReport);

  const close = () => setActiveReport(null);

  return <>
    <Slide direction="left" in={open} unmountOnExit mountOnEnter>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          zIndex: 1200, // Ensure it stays above other content
        }}
      >
        <Paper
          sx={{
            width: "500px",
            height: "90vh",
            position: "absolute",
            top: "5vh",
            right: 0,
            zIndex: 1200,
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            maxWidth: "95vw",
            overflowY: 'auto'
          }}
          elevation={0}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
            <IconButton onClick={close} color='error'><Clear /></IconButton>
          </Box>
          <Divider />
          <Box sx={{ pb: 2, px: 3 }}>
            <Typography variant='h6'>Expense Report</Typography>
            <Typography variant='caption'>Submitted on             {new Date(activeReport?._ts * 1000).toLocaleDateString()}
            </Typography>
            <Typography sx={{ mt: 3, mb: 2 }}>Line Items:</Typography>
            {activeReport?.rows?.map(row => (
              <Box key={row.id} sx={{ borderLeft: `3px solid #0b56a4`, pl: 1, py: 1, mb: 2 }}>
                <Typography variant='body2'>Date: {new Date(row?.date).toLocaleDateString()}</Typography>
                <Typography variant='body2'>Description: {row?.description}</Typography>
                <Typography variant='body2'>Project: {row?.project}</Typography>
                <Typography variant='body2' sx={{fontWeight: 600}}>Total: ${row?.total}</Typography>
              </Box>
            ))}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Typography sx={{fontWeight: 'bold'}}>Total: ${activeReport?.total || 0}</Typography>
            </Box>
          </Box>
        </Paper>
      </div>
    </Slide>
    <Typography variant='h6'>Previous Expense Reports</Typography>
    <Typography variant='body2' sx={{fontStyle: 'italic', mb: 2}}>(Starting 7/31/2024)</Typography>
    <Box sx={{ width: '100%', height: '500px' }}>
      <DataGrid onRowClick={(params) => setActiveReport(params.row)} columns={columns} rows={reports} sx={{ bgcolor: 'white' }} />
    </Box>
  </>
}

export default PreviousExpenseReports;
import React, { useContext, useState, useEffect } from "react";
import { DirectoryContext } from "../../App";
import { useMsal } from "@azure/msal-react";

// mui imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// local imports
import avatarPersonnel from "./assets/avatar-personnel.svg";

// chart imports
import D3 from "./tree";

const views = ["Evergreen Brands", "Transblue", "Condo Shield"];

function NewOrgChart() {
  const { accounts } = useMsal();
  const user = accounts[0];
  const directoryContext = useContext(DirectoryContext);
  const [employees, setEmployees] = useState(directoryContext.employees);
  const [unfilteredEmployees, setUnfilteredEmployees] = useState(
    directoryContext.employees
  );
  console.log(employees.filter((e) => e.displayName === "Sarah Carter"));
  const [admin, setAdmin] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [tree, setTree] = useState(null);
  const [view, setView] = useState("Evergreen Brands");

  useEffect(() => {
    setEmployees(directoryContext.employees);
    setUnfilteredEmployees(directoryContext.employees);
  }, [directoryContext.employees]);

  useEffect(() => {
    console.log(user);
    if (
      user.username?.toLowerCase() === "carters@transblue.org" ||
      user.username?.toLowerCase() === "jim.wescott@transblue.org" ||
      user.username?.toLowerCase() === "jim.wescott@evergreenbrands.com"
    ) {
      setAdmin(true);
    }
  }, []);

  useEffect(() => {
    if (employees.length > 0) {
      mapEmployeeToArray();
    }
  }, [employees]);

  useEffect(() => {
    if (view === "Transblue") {
      setEmployees(
        unfilteredEmployees.filter(
          (e) =>
            e.mail?.includes("evergreenbrands") || e.mail?.includes("transblue")
        )
      );
    } else if (view === "Condo Shield") {
      setEmployees(
        unfilteredEmployees.filter(
          (e) =>
            e.mail?.includes("condoshield") || e.displayName === "Dave Wescott"
        )
      );
    } else if (view === "Evergreen Brands") {
      setEmployees(unfilteredEmployees);
    }
  }, [view]);

  function buildOrganizationTree(employee) {
    const orderEmails = (a, b) => {
      // the order in which domains should be sorted, left to right
      const emailOrder = {
        "evergreenbrands.com": 1,
        "transblue.org": 2,
        "condoshield.org": 3,
        "honey4hope.org": 4,
        "mbridge.global": 5,
      };

      // Extract the domain from the email address
      const getDomain = (mail) => mail.split("@")[1].toLowerCase();

      const domainA = getDomain(a.mail);
      const domainB = getDomain(b.mail);

      // Use the domainOrder object to determine the order
      return emailOrder[domainA] - emailOrder[domainB];
    };

    // Find direct reports for this employee
    const directReports = employees
      .filter((emp) => employee.directReports.includes(emp.id))
      .sort(orderEmails);

    // Create a node for the current employee
    const node = {
      id: employee.id,
      name: employee.displayName,
      mail: employee.mail,
      attributes: {
        avatar: employee.image || avatarPersonnel,
        department: employee.department,
        title: employee.jobTitle,
        totalReports: directReports.length,
        link: `/orgchart/${employee.id}`,
        phone: employee.businessPhones[0]
          ? employee.businessPhones[0]
          : employee.mobilePhone
          ? employee.mobilePhone
          : employee.faxNumber
          ? employee.faxNumber
          : null,
        nonAdminLink: `/directory/employee/${employee.mail.split("@")[0]}`, // open employee card from directory
        mail: employee.mail,
      },
      hasChild: directReports.length > 0,
      hasParent: false,
      isHighlight: true,
      children: [],
    };

    // Recursively build tree for each direct report
    for (const report of directReports) {
      const childNode = buildOrganizationTree(report);
      childNode.hasParent = true;
      if (
        childNode.attributes?.department !== employee.attributes?.department
      ) {
        childNode.department = childNode.attributes?.department;
      }
      node.children.push(childNode);
    }

    return node;
  }

  // Function to map an employee object to the desired array structure
  function mapEmployeeToArray() {
    // Find CEO (assuming there's only one CEO)
    const ceo = employees.find((emp) => emp.displayName === "Dave Wescott");

    // Build organization tree starting from CEO
    const organizationTree = buildOrganizationTree(ceo);
    console.log(organizationTree);
    setTree(organizationTree);
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          maxWidth: "100vw",
          alignItems: "center",
          p: 2,
          position: "static",
        }}
      >
        <Typography variant="h5">Organization Chart</Typography>
        <FormControl
          sx={{ width: "200px", ml: 5, bgcolor: "white" }}
          variant="outlined"
          size="small"
        >
          <InputLabel id="view-select-label">View</InputLabel>
          <Select
            // sx={{ bgcolor: "white", ml: 5 }}
            labelId="view-select-label"
            id="view-select"
            value={view}
            onChange={(e) => setView(e.target.value)}
            label="View"
          >
            {views.map((view) => (
              <MenuItem key={view} value={view}>
                {view}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <div
        style={{
          overflowX: "auto",
          width: "95vw",
          height: "80vh",
          zIndex: 0,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            transform: `scale(${zoom})`,
            zIndex: -1000000,
          }}
        >
          {/* {tree && <Tree tree={tree} />} */}

          {tree && <D3 tree={tree} admin={admin} />}
        </Box>
      </div>
    </>
  );
}

export default NewOrgChart;

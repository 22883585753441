import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Delete, Save } from "@mui/icons-material";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/auth";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

function EditBirthdayModal({ setEditOpen, employee, updateEventsArray }) {
  const [loading, setLoading] = useState(false);
  const { instance } = useMsal();
  const [updates, setUpdates] = useState({
    birthday: employee.birthday,
    hireDate: employee.hireDate,
  });

  const saveUpdates = async () => {
    setLoading(true);
    // get microsoft auth
    const activeAccount = await instance.getActiveAccount();
    let graphAPIToken;
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: activeAccount,
      });
      graphAPIToken = response.accessToken;
    } catch (err) {
      alert("Error getting access token from Microsoft");
      return;
    }

    try {
      await axios
        .post(
          `https://my-tb-cors.herokuapp.com/https://transblueaccount-fns.azurewebsites.net/api/update?databaseId=employees&containerId=employees&id=${employee.id}`,
          updates
        )
        .then((res) => {
          console.log(res);
          updateEventsArray({
            ...employee,
            ...updates,
          });
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }

    try {
      // update employee in microsoft
      await axios
        .patch(
          `https://my-tb-cors.herokuapp.com/https://graph.microsoft.com/v1.0/users/${employee.id}`,
          {
            birthday: new Date(updates.birthday),
            // hireDate: new Date(updates.hireDate),
          },
          {
            headers: {
              Authorization: `Bearer ${graphAPIToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          // setSnackbarOpen(true);
          setEditOpen(false);
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }

    setLoading(false);
  };

  return (
    <>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Edit Birthday and Anniversary
      </Typography>
      <Typography sx={{ fontWeight: 600, mb: 2 }}>
        Employee: {employee.displayName}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          slotProps={{ textField: { size: "small", sx: { my: 2, mr: 2 } } }}
          onChange={(newValue) => {
            setUpdates({
              ...updates,
              birthday: newValue.$d,
            });
          }}
          label="Birthday"
          defaultValue={dayjs(new Date(updates.birthday))}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          slotProps={{ textField: { size: "small", sx: { my: 2 } } }}
          onChange={(newValue) => {
            setUpdates({
              ...updates,
              hireDate: newValue.$d,
            });
          }}
          label="Anniversary"
          defaultValue={dayjs(new Date(updates.hireDate))}
        />
      </LocalizationProvider>
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
          <CircularProgress size="small" />
        </Box>
      )}
      <Box sx={{ display: "flex", mt: 2 }}>
        <Button
          onClick={() => {
            setEditOpen(false);
          }}
          variant="outlined"
          sx={{ fontSize: "11px", mr: 1 }}
          startIcon={<Delete />}
        >
          discard changes
        </Button>
        <Button
          onClick={saveUpdates}
          variant="contained"
          sx={{ fontSize: "11px" }}
          startIcon={<Save />}
          disableElevation
        >
          save entry
        </Button>
      </Box>
    </>
  );
}

export default EditBirthdayModal;

import React, { useEffect, useState } from "react";
import "./assets/index.css";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { emailToSlug } from "../../utils/employees";
import { Buffer } from "buffer";

const EmployeeAvatar = (props) => {
  const navigate = useNavigate();
  const { employee, avatarWidth } = props;

  const handleSelectEmployee = (e) => {
    const nameHandle = emailToSlug(employee.mail);
    navigate(`/directory/employee/${nameHandle}`);
  };

  return (
    <div
      style={{
        width: `${avatarWidth || 50}px`,
        height: `${avatarWidth || 50}px`,
      }}
      className="employeeAvatar"
      onClick={handleSelectEmployee}
      data-testid="employeeCard-employeeImageDiv"
    >
      <Avatar
        children={`${employee.displayName.split(" ")[0][0]}${
          employee.displayName.split(" ")[1][0]
        }`}
        src={employee.image || null}
        sx={{
          width: `${avatarWidth || 50}px`,
          height: `${avatarWidth || 50}px`,
        }}
      />
    </div>
  );
};

export default EmployeeAvatar;

import React, { useContext, useEffect, useState } from "react";
import "./assets/index.css";
import { Avatar } from "@mui/material";
import { idToEmployee } from "../../../../utils/employees";
import { Link, useNavigate } from "react-router-dom";
import { emailToSlug } from "../../utils/string";
import EmployeeHover from "./components/employee-hover";
import { DirectoryContext } from "../../../../App";

const OrganizationChart = (props) => {
  const directoryContext = useContext(DirectoryContext);
  const { employees, departments } = directoryContext;
  const { departmentHead, department } = props;
  const [employeeConfigurations, setEmployeeConfigurations] = useState([]);

  const measureNodes = (headNode, departmentScope) => {
    const prepReports = (directReports) => {
      const mappedReports = directReports?.ap((id) => idToEmployee(id));
      return mappedReports.filter((report) => {
        if (departmentScope === "Transblue") return true;
        if (report.department === departmentScope) return true;
      });
    };
    const measureNode = (node) => {
      let preppedReports = [];
      if (node.directReports) {
        preppedReports = prepReports(node.directReports);
        preppedReports.forEach((report) => {
          measureNode(report);
        });
      } else {
        return 1;
      }
    };
    return measureNode(headNode);
  };

  const generateOrgChart = (headNode, departmentScope) => {
    const employeeConfigurations = [];
    let x = 1;
    let y = 1;
    const dfsAssignCoordinates = (employeeNode) => {
      const mappedReportsObjects = employeeNode.directReports?.ap(
        (reportId) => {
          return idToEmployee(reportId, employees);
        }
      );
      const filteredReports = mappedReportsObjects.filter((report) => {
        if (departmentScope !== "Transblue") {
          if (report.department === departmentScope) {
            return true;
          }
        } else {
          return true;
        }
      });
      const sortedReports = filteredReports.sort((a, b) => {
        if (a.department === b.department) return 0;
        if (a.department < b.department) return -1;
        if (a.department > b.department) return 1;
      });
      const headOfDepartments = departments.filter(
        (department) => department.headId === employeeNode.id
      );
      if (sortedReports.length > 0) {
        let allReportsInitialX = x;
        let directReportsInitialX = x;
        let directReportsFinalX = x;

        y += 2;
        sortedReports.forEach((report, idx) => {
          dfsAssignCoordinates(report);
          if (idx === 0) {
            directReportsInitialX =
              employeeConfigurations[employeeConfigurations.length - 1]
                .employeeContainer.columnStart;
          }
          // last report, asign middle point to manager's x coordinate
          if (idx !== sortedReports.length - 1) {
            x += 2;
          } else {
            directReportsFinalX =
              employeeConfigurations[employeeConfigurations.length - 1]
                .employeeContainer.columnStart;
          }
        });
        y -= 2;
        // managerX is location of manager employeeContainer relative to orgChart
        let managerX = (directReportsFinalX + directReportsInitialX) / 2;

        const employeeConfig = {
          columnStart:
            directReportsInitialX === directReportsFinalX
              ? employeeConfigurations[employeeConfigurations.length - 1]
                  .columnStart
              : directReportsInitialX + 1,
          columnEnd:
            directReportsInitialX === directReportsFinalX
              ? employeeConfigurations[employeeConfigurations.length - 1]
                  .columnEnd
              : directReportsFinalX + 1,
          rowStart: y,
          rowEnd: y + 2,
          employeeContainer: {
            columnStart: managerX,
            columnEnd: managerX + 2,
            alignItems: employeeNode.id === headNode.id ? "center" : "center",
          },
          verticalLineBox: {
            justifyContent:
              employeeNode.id === headNode.id ? "flex-end" : "flex-start",
          },
          verticalLine: {
            height: employeeNode.id === headNode.id ? "50%" : "100%",
          },
          employeeSpread: {
            borderBottom:
              directReportsInitialX === directReportsFinalX
                ? "none"
                : "1px solid black",
          },
          spreadGridColumn: `${managerX} / ${x + 1}`,
          spreadGridRow: `${y} / ${y + 2}`,
          headOfDepartments,
          ...employeeNode,
        };
        employeeConfigurations.push(employeeConfig);
      } else {
        //if final node is a department head, include link to department
        const employeeConfig = {
          columnStart: x,
          columnEnd: x + 2,
          rowStart: y,
          rowEnd: y + 2,
          employeeSpread: {
            borderBottom: "none",
          },
          employeeContainer: {
            columnStart: x,
            columnEnd: x + 2,
            alignItems: "center",
          },
          verticalLineBox: {
            justifyContent: "flex-start",
          },
          verticalLine: {
            height: "50%",
          },
          spreadGridColumn: `${x} / ${x + 2}`,
          spreadGridRow: `${y} / ${y + 2}`,
          headOfDepartments,
          ...employeeNode,
        };
        employeeConfigurations.push(employeeConfig);
      }
    };
    dfsAssignCoordinates(headNode);
    return employeeConfigurations;
  };
  useEffect(() => {
    setEmployeeConfigurations(
      generateOrgChart(departmentHead, department.name)
    );
    // setEmployeeConfigurations(generateOrgChart(departmentHead, 'Transblue'))
  }, [department]);

  return (
    <div
      style={{
        display: "grid",
        columnGap: "0px",
        rowGap: "0px",
        padding: "0px 10%",
      }}
      className="organizationChart"
    >
      {employeeConfigurations.map((configuration) => {
        return (
          <div
            className="employeeSpread"
            style={{
              borderBottom: configuration.employeeSpread.borderBottom,
              width: "100%",
              height: "200px",
              margin: "0",
              gridColumn: `${configuration.columnStart} / ${configuration.columnEnd}`,
              gridRow: `${configuration.spreadGridRow}`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              className="employeeContainer"
              style={{
                width: "100%",
                height: "100%",
                alignItems: `${configuration.employeeContainer.alignItems}`,
              }}
            >
              <EmployeeHover configuration={configuration} />
              <div
                className="verticalLineBox"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: `${configuration.verticalLineBox.justifyContent}`,
                }}
              >
                <div
                  className="verticalLine"
                  style={{
                    height: `${configuration.verticalLine.height}`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OrganizationChart;

import React, { useContext, useState } from "react";
import EmployeeEditor from "./components/employee-editor";
import DirectoryToolbar from "../../../directory/components/directory-toolbar";
import "./assets/index.css";
import { DirectoryContext } from "../../../../App";

const EmployeeManager = (props) => {
  const directoryContext = useContext(DirectoryContext);
  const { employees, setEmployees, departments } = directoryContext;
  const [filteredEmployees, setFilteredEmployees] = useState(employees);

  return (
    <div className="employeeManager">
      <DirectoryToolbar setFilteredEmployees={setFilteredEmployees} />
      {employees.length > 0 ? (
        <>
          {filteredEmployees.map((employee) => {
            return <EmployeeEditor employee={employee} />;
          })}
        </>
      ) : (
        <>Loading...</>
      )}
    </div>
  );
};

export default EmployeeManager;

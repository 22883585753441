import React, { useContext, useEffect, useState } from 'react'
import ItemSelector from '../../../../components/item-selector'
import './assets/index.css'
import { Button } from '@mui/material'
import { DirectoryContext } from '../../../../../../App'

const DepartmentEditor = (props) => {
    const directoryContext = useContext(DirectoryContext);
    const { employees } = directoryContext;
    const { department, departmentHead, handleUpdateDepartments, handleDeleteDepartment } = props
    const [editDepartment, setEditDepartment] = useState(department)
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        setEditDepartment(department)
        setEditing(false)
    }, [department])

    const handleUpdateDepartmentValues = (e) => {
        const updatedDepartment = {
            ...editDepartment,
            [e.target.name]: e.target.value
        }
        setEditDepartment(updatedDepartment)
    }

    const toggleEditDepartment = () => {
        setEditing(!editing)
    }

    const handleClickSave = () => {
        handleUpdateDepartments(editDepartment)
        toggleEditDepartment()
    }

    const handleSelectHead = (departmentHead) => {
        handleUpdateDepartmentValues({
            target: {
                name: 'headId',
                value: departmentHead.id
            }
        })
    }

    return (<div className={`departmentEditor${editing ? ' edit' : ''}`}>
        <div className='departmentInfo'>
            <div className='departmentName'>
                {!editing ? <>
                    <p>{editDepartment.name}</p>
                </> : <>
                    <label>Department Name</label>
                    <input 
                        value={editDepartment.name}
                        name='name'
                        onChange={handleUpdateDepartmentValues}
                    />
                </>}
            </div>
            <div className='departmentHead'>
                <label>Department Head</label>
                {!editing ? <>
                    {departmentHead ? 
                        <p>{departmentHead.displayName}</p> 
                        : <p className='departmentHead warning'>*Head of {department.name} unassigned</p>}
                </> : <>
                    <ItemSelector keyIndicator='displayName' initialItem={departmentHead} handleSelectItem={handleSelectHead} items={employees} /> 
                </>}
            </div>
        </div>
        <div className='buttons'>
            {!editing ? <>
                <Button variant="outlined" onClick={toggleEditDepartment}>edit</Button>
            </> : <>
                <Button variant="outlined" onClick={handleClickSave}>save</Button>
                <Button variant="outlined" onClick={() => handleDeleteDepartment(department)}>delete</Button>
                <Button variant="outlined" onClick={toggleEditDepartment}>cancel</Button>
            </>}
        </div>
    </div>)
}

export default DepartmentEditor

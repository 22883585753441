import React, { useContext, useEffect, useState } from "react";
import "./assets/index.css";
import EmployeeManager from "./features/employee-manager";
import DepartmentManager from "./features/department-manager";
import ToolSelector from "../../components/tool-selector";
import { DirectoryContext } from "../../App";

const Admin = (props) => {
  const [selectedTool, setSelectedTool] = useState("employeeManager");
  const directoryContext = useContext(DirectoryContext);
  const { employees, setEmployees, departments, setDepartments } =
    directoryContext;

  const toolSelectorConfig = {
    baseSlug: "admin",
    selectedTool,
    setSelectedTool,
    tools: [
      {
        display: "Employee Manager",
        name: "employeeManager",
        slug: "employee-manager",
        component: <EmployeeManager />,
      },
      {
        display: "Department Manager",
        name: "departmentManager",
        slug: "department-manager",
        component: <DepartmentManager />,
      },
    ],
  };

  return (
    <div className="admin">
      <h1>Admin Panel</h1>
      <ToolSelector config={toolSelectorConfig} />
      <div className="tools">
        {toolSelectorConfig.tools.map((tool) => {
          if (selectedTool === tool.name) return tool.component;
          return <></>;
        })}
      </div>
    </div>
  );
};

export default Admin;

import React from 'react'
import './assets/index.css'

const UtilityBar = (props) => {
    return (<div className='utilityBar'>
        {props.children}
    </div>)
}

export default UtilityBar

import React, { useContext, useState } from "react";
import FilterIcon from "../../assets/FilterIcon.png";
import "./assets/index.css";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { DirectoryContext } from "../../../../App";
import { Stack } from "@mui/system";
import { getAndSaveEmployees } from "../../../../functions/getAndSaveEmployees";
import { useMsal } from "@azure/msal-react";
import UpdateEmployees from "./updateEmployees";

const DirectoryToolbar = (props) => {
  const { instance, accounts, account } = useMsal();
  const directoryContext = useContext(DirectoryContext);
  const { employees } = directoryContext;
  const { setFilteredEmployees } = props;
  const [departmentFilter, setDepartmentFilter] = useState("All Departments");
  const [nameFilter, setNameFilter] = useState("");
  const [departments, setDepartments] = useState([]);
  const username = accounts[0]?.username?.toLowerCase();

  useEffect(() => {
    setDepartments((prev) => [
      "All Departments",
      ...new Set(
        employees
          .filter((employee) => employee.department)
          .map((employee) => employee.department)
      ),
    ]);
    setFilteredEmployees([...employees]);
  }, [employees]);

  const handleChangeNameFilter = (e) => {
    setNameFilter(e.target.value);
  };

  const handleChangeDepartmentFilter = (e) => {
    setDepartmentFilter(e.target.value);
  };

  const handleClearFilters = () => {
    setDepartmentFilter("All Departments");
    setNameFilter("");
  };

  useEffect(() => {
    if (departmentFilter === "All Departments") {
      setFilteredEmployees([...employees]);
    } else {
      const filteredByDepartment = employees.filter(
        (employee) => employee.department === departmentFilter
      );
      setFilteredEmployees(filteredByDepartment);
    }
  }, [departmentFilter]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (nameFilter.length === 0) {
        setFilteredEmployees(employees);
      } else {
        let array = employees.filter((employee) =>
          employee.displayName.toLowerCase().includes(nameFilter.toLowerCase())
        );
        console.log(array);
        setFilteredEmployees(array);
      }
    }, 500);
    return () => clearTimeout(debounce);
  }, [nameFilter]);

  return (
    <Stack direction="row" spacing={2} mb={2}>
      <TextField
        id="filterByEmployeeName"
        className="filterByName"
        label="Filter by name"
        inputProps={{ "data-testid": "directoryHome-filterByNameInput" }}
        value={nameFilter}
        onChange={handleChangeNameFilter}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={FilterIcon} />
            </InputAdornment>
          ),
        }}
        size="small"
      />
      <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
        <InputLabel id="demo-select-small-label">
          Filter by department
        </InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          label="Filter by department"
          variant="outlined"
          data-testid="clearFilters"
          onChange={handleChangeDepartmentFilter}
          size="small"
          value={departmentFilter}
        >
          {departments.map((department) => {
            return (
              <MenuItem key={department} value={department}>
                {department}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        data-testid="clearFilters"
        onClick={handleClearFilters}
        size="small"
        sx={{ textTransform: "capitalize" }}
      >
        Clear filters
      </Button>

      {username === "carters@transblue.org" ||
      username === "jim.wescott@transblue.org" ||
      username === "jim.wescott@evergreenbrands.com" ? (
        <UpdateEmployees />
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default DirectoryToolbar;

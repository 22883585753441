export const generateUniqueDepartments = (employees) => {
  const uniqueDepartments = {};
  employees.forEach((employee) => {
    if (!uniqueDepartments[employee.department])
      uniqueDepartments[employee.department] = 1;
  });
  return Object.keys(uniqueDepartments).filter(
    (department) => department !== "null"
  );
};

export const emailToEmployee = (email, employees) => {
  if (!email) return null;
  const filterResults = employees.filter(
    (e) => e.mail.toLowerCase() === email.toLowerCase()
  );
  if (filterResults.length > 0) return filterResults[0];
  else return null;
};

export const idToEmployee = (id, employees) => {
  if (!id) return null;
  const filterResults = employees.filter((e) => e.id === id);
  if (filterResults.length > 0) return filterResults[0];
  else return null;
};

export const emailToSlug = (string) => {
  let handle = string?.split("@")[0];
  return handle?.toLowerCase();
};

export const slugToEmail = (slug) => {
  let email = slug;
  email += "@transblue.org";
  return email;
};

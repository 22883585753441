import React, { useContext, useState } from 'react'
import './assets/index.css'
import RequestDetails from '../request-details'
import { Alert, Button, Dialog } from '@mui/material'
import { Close } from '@mui/icons-material'
import { findRequestReviewStatus } from '../../utils/requests'
import { DirectoryContext } from '../../App'

const ReviewRequestCard = (props) => {
    const directoryContext = useContext(DirectoryContext);
    const { employees } = directoryContext;
    const { request, reviewRequest, denyRequest, manager } = props
    const { request_maker_id } = request
    const [clubhouseError, setClubhouseError] = useState('')

    const findRequesterName = () => employees.filter(e => e.id === request_maker_id)[0].displayName

    const handleAcceptRequest = async () => {
        const approved = true
        try {
            await reviewRequest(request, approved)
        } catch (err) {
            setClubhouseError(err.response.data.message)
            console.log(err)
        }
    }

    const handleDenyRequest = async () => {
        try {
            await reviewRequest(request)
        } catch (err) {
            setClubhouseError(err.response.data.message)
            console.log(err)
        }
    }

    return (<div className='reviewRequestCard'>
        {employees.length > 0 ? <h3>{findRequesterName()}</h3> : <></>}
        <div className='requestDetailsAndButtons'>
            <RequestDetails request={request} />
            {findRequestReviewStatus(request, manager.id) === 'Needs Review' ? <>
                <div className='buttons'>
                    <Button onClick={handleAcceptRequest} sx={{ color: 'green', border: 'solid 1px green', marginBottom: '10px' }}>Accept</Button>
                    <Button onClick={handleDenyRequest} sx={{ color: 'red', border: 'solid 1px red' }}>Deny</Button>
                </div>
            </> : findRequestReviewStatus(request, manager.id) === 'Approved' ? <>
                <p style={{ color: '#66C34F' }}>Approved</p>
            </> : <>
                <p style={{ color: '#CD4F4F' }}>Denied</p>
            </>}
        </div>
        <Dialog open={clubhouseError}>
            <Close sx={{ marginLeft: 'auto' }} onClick={() => setClubhouseError('')}>Close</Close>
            <Alert severity='warning'>{clubhouseError}</Alert>
        </Dialog>

    </div>)
}

export default ReviewRequestCard

import {
  CalendarMonth,
  Dashboard,
  Group,
  Laptop,
  Person,
} from "@mui/icons-material";
import {
  Box,
  CssBaseline,
  IconButton,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Drawer,
  Divider,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { ChevronLeft, ChevronRight, Menu } from "@mui/icons-material";
import ArticleIcon from "@mui/icons-material/Article";
import MuiAppBar from "@mui/material/AppBar";
import { styled, useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import msalInstance from "../../auth/msalInstance";
import { DirectoryContext } from "../../App";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const leadership = [
  "carters@transblue.org",
  "david.vetter@evergreenbrands.com",
  "jim.wescott@transblue.org",
  "jim.wescott@evergreenbrands.com",
];

function PageLayout(props) {
  const { accounts } = useMsal();
  const user = accounts[0];
  const navigate = useNavigate();
  const { children, currentEmployee } = props;
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const activeAccount = msalInstance.getActiveAccount();

  const directoryContext = useContext(DirectoryContext);
  const { executive } = directoryContext;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const pages = [
    { slug: "/", title: "Dashboard", icon: <Dashboard /> },
    { slug: "/calendar", title: "Calendar", icon: <CalendarMonth /> },
    // { slug: '/clubhouse', title: 'Clubhouse' },
    // // { slug: '/wfh', title: 'Work From Home' },
    // { slug: '/birthdays', title: 'Birthdays' },
    // { slug: '/vacation', title: 'Vacation' },
    // // { slug: '/requests', title: 'Requests' },
    // // { slug: '/hierarchy', title: 'Hierarchy' },
    { slug: "/directory", title: "Directory", icon: <Person /> },
    { slug: "/orgchart", title: "Org Chart", icon: <Group /> },
    { slug: "/expenseReport", title: "Expense Report", icon: <ArticleIcon /> },
  ];

  if (executive) {
    pages.push({
      slug: "/wfhrequests",
      title: "WFH Requests",
      icon: <Laptop />,
    });
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              width: "400px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <Menu />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Employee Management
            </Typography>
          </div>
          <div
            style={{
              width: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {currentEmployee.directReports?.length > 0 ? (
              <>
                <Link to={"/manager-admin"}>Manager</Link>
              </>
            ) : (
              <>
                {process.env.NODE_ENV !== "production" ? (
                  <Link to={"/manager-admin"}>Manager</Link>
                ) : (
                  <></>
                )}
              </>
            )}
            {activeAccount.username.toLowerCase() === "carters@transblue.org" ||
            activeAccount.username.toLowerCase() ===
              "jim.wescott@transblue.org" ? (
              <>
                <Link to={"/admin"}>Admin</Link>
              </>
            ) : (
              <></>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {pages.map((page) => {
            return (
              <ListItem disablePadding key={page.title}>
                <ListItemButton
                  onClick={() => {
                    navigate(page.slug);
                    handleDrawerClose();
                  }}
                >
                  <ListItemIcon>{page.icon}</ListItemIcon>
                  <ListItemText primary={page.title} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <Divider />

        {/* {leadership.includes(user.username.toLowerCase()) && (
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/requests")}>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Time Off Requests" />
              </ListItemButton>
            </ListItem>
          </List>
        )} */}
      </Drawer>
      <Main
        open={open}
        sx={{
          flexGrow: 1,
          p: 2,
          bgcolor: "#f5f5f5",
          minHeight: "100vh",
          borderLeft: "1px solid #e4e5e7",
        }}
      >
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}

export default PageLayout;

import React, { useEffect, useState } from 'react'
import './assets/index.css'



const ToggleButton = (props) => {
    const { options, onChange, name, initialOption, locked } = props
    const [currentOption, setCurrentOption] = useState(initialOption)
    
    useEffect(() => {
        setCurrentOption(initialOption)
    }, [initialOption])

    const findNextValue = () => {
        let nextIdx
        if (currentOption.value === null) {
            setCurrentOption(options[0])
            nextIdx = 0
        }
        else {
            let currentIdx
            options.forEach((o, idx) => {
                if (o.value === currentOption.value) currentIdx = idx
            })
            if (currentIdx === options.length - 1) nextIdx = 0
            else nextIdx = currentIdx+1
            setCurrentOption(options[nextIdx])
        }
        onChange({
            target: {
                name: name,
                value: options[nextIdx].value
            }
        })
    }

    return <button 
        className={`toggleButton${locked ? ' locked' : ''}`}
        style={{ backgroundColor: currentOption.backgroundColor }}
        onClick={!locked ? findNextValue : null}
        name={name}
    >{currentOption?.display}</button>
}

export default ToggleButton

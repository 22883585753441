import React from 'react'
import './assets/index.css'

const RequestDetails = (props) => {
    const { request } = props

    const calculateTimeOffHours = (from, to) => {
        const firstDay = new Date(from)
        let currentDay = new Date(firstDay)
        let lastDay = new Date(to)
        let hourCount = 0
        while (currentDay <= lastDay) {
            if (currentDay.getDay() !== 6 && currentDay.getDay() !== 0) {
                if (currentDay.toDateString() === lastDay.toDateString()) {
                    hourCount += lastDay.getHours() - currentDay.getHours()
                }
                else {
                    const endOfCurrentDay = new Date(currentDay)
                    endOfCurrentDay.setHours(17, 0)
                    hourCount += endOfCurrentDay.getHours() - currentDay.getHours()
                    if (currentDay.toDateString() === firstDay.toDateString()) currentDay.setHours(9, 0)
                }
                // ////////////////
                // /////////////
                // // consider holidays
                // ////////////
                // /////
            }
            currentDay.setDate(currentDay.getDate()+1)
        }
        return hourCount
    }

    return (<div className='requestTypeSpecifics'>
        {/* make component for time off request */}
        <div className='requestTypeTitle'>
            <h3>Time Off</h3>
        </div>
        <div className='requestTypeValues'>
            <div className='fromTo'>
                <div className='labelAndValue from'>
                    <label>From:</label>
                    <p>{new Date(request.time_off_request_from).toLocaleString()}</p>
                </div>
                <div className='labelAndValue to'>
                    <label>To:</label>
                    <p>{new Date(request.time_off_request_to).toLocaleString()}</p>
                </div>
            </div>
            <div className='hoursNote'>
                <div className='labelAndValue hours'>
                    <label>Hours:</label>
                    <p>{calculateTimeOffHours(request.time_off_request_from, request.time_off_request_to)}</p>
                </div>
                <div className='labelAndValue note'>
                    <label>Note:</label>
                    <p>{request.time_off_request_note}</p>
                </div>
            </div>
        </div>
    </div>)
}

export default RequestDetails

import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import './assets/index.css'
import OrganizationChart from '../../features/organization-chart'
import { DirectoryContext } from '../../../../App'

const DepartmentInfo = (props) => {
    const directoryContext = useContext(DirectoryContext);
    const {employees, departments} = directoryContext;

    const [departmentHead, setDepartmentHead] = useState(false)
    const [department, setDepartment] = useState(false)
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (employees.length > 0) {
            const targetDepartment = params['department']
            const filteredDepartment = departments.filter(department => department.name.toLowerCase() === targetDepartment.toLowerCase())
            if (filteredDepartment.length === 0) navigate('/directory')
            const departmentHead = employees.filter(employee => employee.id === filteredDepartment[0].headId)[0]
            setDepartmentHead(departmentHead)
            setDepartment(filteredDepartment[0])
        }
    }, [params, employees])

    return (department ? <>
        <div className='departmentInfo'>
            {department.name !== 'Leadership' ? <>
                <Link to={`/directory/departments/${departmentHead.department.toLowerCase()}`}>{departmentHead.department}</Link>
            </> : <></>}
            <h1>{department.name}</h1>
            {department ? <OrganizationChart 
                department={department}
                departmentHead={departmentHead}
            /> : <></>}
        </div>
    </> : <>Loading...</>)
}

export default DepartmentInfo

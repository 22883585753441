import React from 'react';
import {
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	Stack
} from '@mui/material';
import styled from '@emotion/styled';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { init } from 'emailjs-com';
import * as emailjs from 'emailjs-com';
init('user_iLZ3jXyTzXi5zQFlgf5DG');

const useStyles = styled((theme) => ({
	formControl: {
		marginBottom: theme.spacing(2),
		minWidth: 200
	},
	submitButton: {
		marginTop: theme.spacing(2)
	}
}));

const initialValues = {
	details: '',
	urgency: '',
	department: ''
};

function IntakeRequest({ setIntakeOpen }) {
	const { accounts } = useMsal();
	const email = accounts[0]?.username;
	const username = accounts[0]?.name;
	const classes = useStyles();

	const [request, setRequest] = React.useState(initialValues);

	const handleChange = (e) => {
		setRequest({
			...request,
			[e.target.id]: e.target.value
		});
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		// Process the form data or make API calls here
		request.date = new Date().getTime();
		request.user = username;
		request.status = 'New';
		request.email = email;
		// save to database
		axios
			.post(
				`https://my-tb-cors.herokuapp.com/https://transblueaccount-fns.azurewebsites.net/api/save?containerId=intake&databaseId=employees`,
				request
			)
			.then((res) => {
				console.log(res.data);
				const id = res.data.id;
				// send email notification

				emailjs.send(
					'service_gekurtf',
					'template_5isz9zw', //CONTACT TEMPLATE
					{
						...request,
						date: new Date(request.date).toLocaleDateString(),
						url: `https://employees.transblueconnect.com/intake/${id}`
					},
					'user_iLZ3jXyTzXi5zQFlgf5DG'
				);

				setIntakeOpen(false);
				setRequest(initialValues);
			});
	};

	return (
		<Stack direction='column' spacing={3}>
			<form onSubmit={handleSubmit}>
				<FormControl
					variant='outlined'
					fullWidth
					className={classes.formControl}
					size='small'
					sx={{ mb: 3 }}
				>
					<InputLabel>Department</InputLabel>
					<Select
						value={request.department}
						onChange={(e) =>
							setRequest({
								...request,
								department: e.target.value
							})
						}
						label='Department'
					>
						<MenuItem value='Operations'>Operations</MenuItem>
						<MenuItem value='Accounting'>Accounting</MenuItem>
						<MenuItem value='Marketing / IT'>
							Marketing / IT
						</MenuItem>
						<MenuItem value='Sales'>Sales</MenuItem>
					</Select>
				</FormControl>
				<FormControl
					variant='outlined'
					fullWidth
					className={classes.formControl}
					size='small'
					sx={{ mb: 3 }}
				>
					<InputLabel>Urgency</InputLabel>
					<Select
						value={request.urgency}
						onChange={(e) =>
							setRequest({
								...request,
								urgency: e.target.value
							})
						}
						label='Urgency'
					>
						<MenuItem value='High 1 week'>High 1 week</MenuItem>
						<MenuItem value='Medium 2 weeks'>
							Medium 2 weeks
						</MenuItem>
						<MenuItem value='Low'>Low</MenuItem>
					</Select>
				</FormControl>
				<TextField
					sx={{ mb: 3 }}
					label='Request Details'
					variant='outlined'
					fullWidth
					multiline
					rows={4}
					value={request.details}
					onChange={handleChange}
					id='details'
					className={classes.formControl}
				/>
				<Button
					type='submit'
					variant='contained'
					color='primary'
					className={classes.submitButton}
					fullWidth
					sx={{ fontSize: '12px' }}
				>
					Submit
				</Button>
			</form>
		</Stack>
	);
}

export default IntakeRequest;

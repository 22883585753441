const getDaysOfWeekBetweenDates = (startDate, endDate) => {
	// endDate.setDate(endDate.getDate() + 1);

	let start = new Date(startDate);
	let end = new Date(endDate);

	let hours = 0;

	while (start <= end) {
		console.log(start.getDay());

		// saturday = 6 and sunday = 0
		if (start.getDay() !== 6 && start.getDay() !== 0) {
			hours += 8;
		}

		start.setDate(start.getDate() + 1);
	}

	console.log(hours);

	return hours;
};

export default getDaysOfWeekBetweenDates;

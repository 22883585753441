import React, { useContext, useState } from 'react'
import './assets/index.css'
import AddNewDepartmentForm from '../../components/new-department-form'
import DepartmentEditor from './components/department-editor'
import { Button } from '@mui/material'
import axios from 'axios'
import ClubhouseService from '../../../../api/ClubhouseService'
import { clubhouseFnsRequest } from '../../../../config/auth'
import { useMsal } from '@azure/msal-react'
import UtilityBar from '../../../../components/utility-bar'
import { DirectoryContext } from '../../../../App'

const DepartmentManager = (props) => {
    const { instance } = useMsal()
    const directoryContext = useContext(DirectoryContext);
    const { departments, setDepartments, employees } = directoryContext;

    const [newDepartmentFormVisible, setNewDepartmentFormVisible] = useState(false)

    const handleDeleteDepartment = async (selectedDepartment) => {
        const config = { departmentId: selectedDepartment.id }
        try {
            const clubhouseFnsTokenResponse = await instance.acquireTokenSilent({
                ...clubhouseFnsRequest
            })
            const clubhouseFnsToken = clubhouseFnsTokenResponse.accessToken
            await ClubhouseService.deleteDepartment({ departmentId: selectedDepartment.id, clubhouseFnsToken })
            const updatedDepartments = departments.filter(department => {
                return selectedDepartment.id !== department.id
            })
            setDepartments(updatedDepartments)
        } catch(err) {
            console.log(err)
        }
    }

    const handleUpdateDepartments = async (updatedDepartment) => {
        try {
            const clubhouseFnsTokenResponse = await instance.acquireTokenSilent({
                ...clubhouseFnsRequest
            })
            const clubhouseFnsToken = clubhouseFnsTokenResponse.accessToken
            await ClubhouseService.updateDepartment({ updatedDepartment, clubhouseFnsToken })
            const updatedDepartments = departments.map(department => {
                if (updatedDepartment.id === department.id) {
                    return updatedDepartment
                }
                return department
            })
            setDepartments(updatedDepartments)
        } catch(err) {
            console.log(err)
        }
    }

    const toggleAddNewDepartmentForm =() => {
        setNewDepartmentFormVisible(!newDepartmentFormVisible)
    }

        

    const handleCreateNewDepartment = async (newDepartment) => {
        try {
            const clubhouseFnsTokenResponse = await instance.acquireTokenSilent({
                ...clubhouseFnsRequest
            })
            const clubhouseFnsToken = clubhouseFnsTokenResponse.accessToken
            const createdDepartment = await ClubhouseService.createDepartment({ newDepartment, clubhouseFnsToken })
            setDepartments([
                ...departments,
                {
                    ...newDepartment,
                    id: createdDepartment.data.createdId
                }
            ])
            setNewDepartmentFormVisible(false)
        } catch(err) {
            console.log(err)
        }
    }

    return (<div className='departmentManager'>
        <UtilityBar>
            <Button variant="outlined" onClick={toggleAddNewDepartmentForm}>Add Department</Button>
        </UtilityBar>
        {newDepartmentFormVisible ? <AddNewDepartmentForm handleCreateNewDepartment={handleCreateNewDepartment} setNewDepartmentFormVisible={setNewDepartmentFormVisible} /> : <></>}
        {departments.length > 0 ? <>
            {departments.map(department => {
                const departmentHeadFilter = employees.filter(e => e.id === department.headId)
                const departmentHead = departmentHeadFilter.length > 0 ? departmentHeadFilter[0] : null
                return <DepartmentEditor 
                    handleDeleteDepartment={handleDeleteDepartment} 
                    departmentHead={departmentHead} 
                    handleUpdateDepartments={handleUpdateDepartments} 
                    departmentItem={departmentHead}
                    department={department} 
                />
            })}
        </> : <>Loading...</>}
    </div>)
}

export default DepartmentManager

import React, { useContext, useEffect, useState } from "react";
import EvaluationForm from "../evaluation-form";
import { findLastQuarter } from "../../../../../../utils/date";
import ClubhouseService from "../../../../../../api/ClubhouseService";
import { idToEmployee } from "../../../../../../utils/employees";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import evaluationFormConfig from "../evaluation-form/config";
import { useMsal } from "@azure/msal-react";
import { clubhouseFnsRequest } from "../../../../../../config/auth";
import "./assets/index.css";
import { DirectoryContext } from "../../../../../../App";

const TeamEvaluator = (props) => {
  const { directoryContext } = useContext(DirectoryContext);
  const { employees } = directoryContext;
  const { instance } = useMsal();
  const { evaluatorOfTeam, managerOfTeam, manager } = props;
  const [reportsWithEvaluations, setReportsWithEvaluations] = useState([]);
  const [relevantQuarters, setRelevantQuarters] = useState([]);
  const [selectedQuarter, setSelectedQuarter] = useState(findLastQuarter());

  useEffect(() => {
    if (managerOfTeam) {
      (async () => {
        const clubhouseFnsTokenResponse = await instance.acquireTokenSilent({
          ...clubhouseFnsRequest,
        });
        const clubhouseFnsToken = clubhouseFnsTokenResponse.accessToken;
        const lastQuarter = findLastQuarter();
        const uniqueQuarters = {};
        const reportCollector = [];
        for (let i = 0; i < managerOfTeam.directReports?.length; i++) {
          const currentReportId = managerOfTeam.directReports[i];
          const currentReport = idToEmployee(currentReportId, employees);
          const currentReportEvaluations =
            await ClubhouseService.getEvaluationsById({
              employeeId: currentReportId,
              clubhouseFnsToken,
            });
          currentReportEvaluations.data.forEach((e) => {
            uniqueQuarters[e.evaluation_quarter] = 1;
          });
          const reportWithEvaluations = {
            ...currentReport,
            evaluations: currentReportEvaluations.data,
          };
          reportCollector.push(reportWithEvaluations);
        }
        uniqueQuarters[lastQuarter] = 1;
        const quarterSelections = Object.keys(uniqueQuarters).sort((a, b) => {
          if (a > b) return -1;
          if (a < b) return 1;
          else return 0;
        });
        setRelevantQuarters(quarterSelections);
        setReportsWithEvaluations(reportCollector);
      })();
    }
  }, [managerOfTeam]);

  useEffect(() => {
    // reset relevant quarters if selected quarter is not applicable
    if (!relevantQuarters.includes(selectedQuarter))
      setSelectedQuarter(findLastQuarter());
  }, [relevantQuarters]);

  const updateReportsWithEvaluation = (evaluation) => {
    const updatedReportsWithEvaluations = reportsWithEvaluations.map(
      (report) => {
        if (report.id !== evaluation.evaluation_employee_id) return report;
        const updatedReportEvaluations = report.evaluations;
        updatedReportEvaluations.push(evaluation);
        const updatedReport = {
          ...report,
          evaluations: updatedReportEvaluations,
        };
        return updatedReport;
      }
    );
    setReportsWithEvaluations(updatedReportsWithEvaluations);
  };

  return (
    <div className="reportsEvaluations">
      {reportsWithEvaluations.length > 0 ? (
        <>
          <FormControl>
            <InputLabel id="selectQuarterLabel">Selected Quarter</InputLabel>
            <Select
              input={<OutlinedInput label="Selected Quarter" />}
              className="selectQuarter"
              labelId="selectQuarterLabel"
              id="selectQuarter"
              inputProps={{ "data-testid": "managerAdmin-selectQuarter" }}
              value={selectedQuarter}
              onChange={(e) => setSelectedQuarter(e.target.value)}
              sx={{ width: "125px", marginBottom: "10px" }}
            >
              {relevantQuarters.map((q) => (
                <MenuItem value={q}>{q}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {reportsWithEvaluations.map((e) => {
            //////
            ////////////
            // sort by most recent date_created
            ////////////
            /////////
            const relavantEvaluationValues = e.evaluations.filter((ev) => {
              if (ev.evaluation_quarter === selectedQuarter) {
                if (ev.evaluation_evaluator_id === evaluatorOfTeam.id)
                  return true;
              }
            });
            let initialFormValues;
            if (relavantEvaluationValues.length === 0) {
              initialFormValues = {
                ...evaluationFormConfig.initialCurrentEvaluationValues,
              };
            } else {
              initialFormValues = {
                ...relavantEvaluationValues[0],
              };
            }
            initialFormValues.evaluation_evaluator_id = evaluatorOfTeam.id;
            initialFormValues.evaluation_employee_id = e.id;
            initialFormValues.evaluation_quarter = selectedQuarter;
            return (
              <EvaluationForm
                manager={manager}
                employee={e}
                initialFormValues={initialFormValues}
                updateReportsWithEvaluation={updateReportsWithEvaluation}
              />
            );
          })}
        </>
      ) : (
        <>loading...</>
      )}
    </div>
  );
};

export default TeamEvaluator;

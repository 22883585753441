const config = {
    axios: {
        microsoftGraph: {
            baseURL: 'https://graph.microsoft.com/v1.0'
        },
        clubhouseService: {
            baseURL: 'https://my-tb-cors.herokuapp.com/https://clubhouse-fns.azurewebsites.net/api',
            // baseURL: 'https://clubhouse-fns-staging.azurewebsites.net/api',
        }
    },
    REACT_APP_MSAL_CLIENTID: 'd37de22b-21f2-4dce-8f68-5573cbf667ab',
    REACT_APP_CLUBHOUSE_APP_ID: 'a310034b-53e1-46cb-bd39-c2c9c87a9d7d',
    REACT_APP_SACRED_GROUNDS_GROUP_ID: 'bb954c02-2944-478a-9219-bbbe5ec233f4',
    REACT_APP_TRANSBLUE_TENANT_ID: '2d9e059e-0723-4845-9fdc-2278bce3f3dc'
}

export default config

const green = '#66C34F'
const yellow = '#F3B30C'
const red = '#CD4F4F'
export default {
    coreValuesConfigurations: [
        { display: 'Be Nimble', name: 'evaluation_be_nimble' },
        { display: 'Have Grit', name: 'evaluation_have_grit' },
        { display: 'For Each Other', name: 'evaluation_for_each_other' },
        { display: 'Help First', name: 'evaluation_help_first' },
        { display: 'Be Engaged', name: 'evaluation_be_engaged' },
        { display: 'See It Through', name: 'evaluation_see_it_through' }
    ],
    gwcConfigurations: [
        { display: 'G', name: 'evaluation_g' },
        { display: 'W', name: 'evaluation_w' },
        { display: 'C', name: 'evaluation_c' }
    ],
    initialCurrentEvaluationValues: {
        evaluation_g: null,
        evaluation_w: null,
        evaluation_c: null,
        evaluation_be_nimble: null,
        evaluation_have_grit: null,
        evaluation_for_each_other: null,
        evaluation_help_first: null,
        evaluation_be_engaged: null,
        evaluation_see_it_through: null,
    },
    gwcBaseOptions: [ 
        {  value: 1, backgroundColor: green }, 
        { value: 0, backgroundColor: red } 
    ],
    coreValueBaseOptions: [
        { value: '+', backgroundColor: green, display: '+'},
        { value: '+/-', backgroundColor: yellow, display: '+/-'},
        { value: '-', backgroundColor: red, display: '-'}
    ],
    gwcBaseInitialOption: {
        backgroundColor: 'lightGray',
        value: null
    },
    coreValueInitialOption: {
        display: '+/-',
        backgroundColor: 'lightGray',
        value: null
    }
}
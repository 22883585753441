import React, { useState } from 'react'
import { useEffect } from 'react'
import './assets/index.css'

const ItemSelector = (props) => {
    const { items, initialItem, handleSelectItem, keyIndicator } = props
    const [selectedItem, setSelectedItem] = useState(initialItem)
    const [inputValue, setInputValue] = useState('')
    const [selections, setSelections] = useState([])
    const [searching, setSearching] = useState(false)

    useEffect(() => {
        if (inputValue) {
            let count = 0
            const filteredItem = items.filter(e => {
                if (count < 6) {
                    if (e[keyIndicator].toLowerCase().includes(inputValue.toLowerCase())) {
                        count ++
                        return e
                    }
                }
            })
            setSelections(filteredItem)
        } else {
            setSelections([])
        }
    }, [inputValue])

    let mouseoverselections = false
    useEffect(() => {
        if (searching) {
            const input = document.getElementById('item-selector-input')
            const selections = document.getElementById('itemSelector-selections')
            selections.addEventListener('mouseover', () => mouseoverselections = true)
            selections.addEventListener('mouseleave', () => mouseoverselections = false)
            input.focus()
            input.select()
            input.addEventListener('blur', (e) => {
                if (!mouseoverselections) {
                    setSearching(false)
                }
            })
        }
    }, [searching])

    const onChangeInput = (e) => {
        setInputValue(e.target.value)
    }

    const handleClickItem = (selection) => {
        setSelectedItem(selection)
        setSearching(false)
        setInputValue(selection[keyIndicator])
        handleSelectItem(selection)
    }

    const handleEditItem = () => {
        setSearching(true)
    }

    return <div className='itemSelector'>
        {searching ? <>
            <input 
                id={`item-selector-input`}
                onChange={onChangeInput}
                value={inputValue}
            />
            <div className='selections' id='itemSelector-selections'>
                {selections.map(selection => {
                    return <div className='selection' onClick={() => handleClickItem(selection)}>
                        {selection[keyIndicator]}
                    </div>
                })}
            </div>
        </> : <>
            <div className='selection' onClick={handleEditItem}>
                {selectedItem ? selectedItem[keyIndicator] : 'Select'}
            </div>

        </>}
    </div>
}

export default ItemSelector

import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { emailToSlug } from "../../../../utils/employees";
import EmployeeCard from "../employee-card";
import "./assets/index.css";
import EmployeeContactLinks from "../employee-contact-links";
import EmployeeAvatar from "../../../../components/employee-avatar";
import { DirectoryContext } from "../../../../App";

const EmployeeInfo = (props) => {
  const directoryContext = useContext(DirectoryContext);
  const { employees } = directoryContext;
  const [employee, setEmployee] = useState(false);
  const [employeeManager, setEmployeeManager] = useState(false);
  const [employeeDirectReports, setEmployeeDirectReports] = useState([]);
  const params = useParams();

  useEffect(() => {
    (async () => {
      const employeeSlug = params["employeeSlug"];
      const employee = employees.find(
        (e) => emailToSlug(e.mail) === employeeSlug.toLowerCase()
      );
      console.log(employee);

      const manager = employees.find((e) => e.id === employee.manager);
      if (manager) {
        setEmployeeManager(manager);
      }

      let directReports = employees.filter((e) => e.manager === employee.id);
      setEmployee(employee);
      setEmployeeDirectReports(directReports);
    })();
  }, [params]);

  return (
    <div className="employeeInfo" data-testid="employeeInfo">
      <Link className="backLink" to="/directory">
        Back To Directory
      </Link>
      {employee ? (
        <>
          <EmployeeCard employee={employee} cardWidth={400} />
          <Link to={`/directory/departments/${employee.department}`}>
            <p>{employee.department}</p>
          </Link>
          <EmployeeContactLinks employee={employee} />
          {employeeManager ? (
            <>
              <div className="manager" data-testid="employeeCard-manager">
                <h3>Manager</h3>
                <EmployeeCard employee={employeeManager} cardWidth={250} />
              </div>
            </>
          ) : (
            <></>
          )}
          {employeeDirectReports?.length > 0 ? (
            <>
              <div
                className="directReports"
                data-testid="employeeCard-directReports"
              >
                <h3>Direct Reports</h3>
                <div className="employeeContainer">
                  {employeeDirectReports?.map((employee) => {
                    return (
                      <EmployeeCard
                        employee={employee}
                        cardWidth={250}
                        key={employee.mail}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>loading...</>
      )}
    </div>
  );
};

export default EmployeeInfo;

import React, { useEffect, useState } from 'react'
import ClubhouseService from '../../../../../../api/ClubhouseService'
import './assets/index.css'
import ToggleButton from '../../../../../../components/toggle-button'
import evaluationFormConfig from './config'
import { findLastQuarter } from '../../../../../../utils/date'
import { useMsal } from '@azure/msal-react'
import { clubhouseFnsRequest } from '../../../../../../config/auth'
import EmployeeAvatar from '../../../../../../components/employee-avatar'

const EvaluationForm = (props) => {
    const { instance } = useMsal()
    const { updateReportsWithEvaluation, initialFormValues, employee, manager } = props
    const [editToggled, setEditToggled] = useState(false)
    const [currentEvaluationValues, setCurrentEvaluationValues] = useState(initialFormValues)

    useEffect(() => {
        setCurrentEvaluationValues(initialFormValues)
    }, [initialFormValues])

    useEffect(() => {
        if (currentEvaluationValues.evaluation_quarter !== findLastQuarter()) setEditToggled(false)
    }, [currentEvaluationValues.evaluation_quarter])

    const handleChange = (e) => {
        setCurrentEvaluationValues({
            ...currentEvaluationValues,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmitEvaluation = async () => {
        try {
            const clubhouseFnsTokenResponse = await instance.acquireTokenSilent({
                ...clubhouseFnsRequest
            })
            const clubhouseFnsToken = clubhouseFnsTokenResponse.accessToken
            await ClubhouseService.postEvaluation({ evaluation: currentEvaluationValues, clubhouseFnsToken })
            updateReportsWithEvaluation(currentEvaluationValues)
        } catch (err) {
            console.log(err)
        }
    }
    
    const toggleEditEvaluation = () => {
        setEditToggled(!editToggled)
    }

    const cancelEditValues = () => {
        setCurrentEvaluationValues(initialFormValues)
        toggleEditEvaluation()
    }

    const handleUpdateEvaluation = async () => {
        try {
            const clubhouseFnsTokenResponse = await instance.acquireTokenSilent({
                ...clubhouseFnsRequest
            })
            const clubhouseFnsToken = clubhouseFnsTokenResponse.accessToken
            await ClubhouseService.updateEvaluationById({ updatedEvaluation: currentEvaluationValues, clubhouseFnsToken })
            toggleEditEvaluation()
        } catch (err) {
            console.log(err)
        }
    }

    const currentQuarterEvaluationExists = (employee) => {
        let evaluationCompleted = false
        employee.evaluations.forEach(evaluation => {
            if (evaluation.evaluation_quarter === initialFormValues.evaluation_quarter) evaluationCompleted = true
        })
        return evaluationCompleted
    }

    const formLocked = () => {
        if (currentQuarterEvaluationExists(employee) && !editToggled) return true
        if (currentEvaluationValues.evaluation_quarter !== findLastQuarter()) return true
        if (currentEvaluationValues.evaluation_evaluator_id !== manager.id) return true
        return false
    }

    return (<div className='evaluationForm'>
        <div className='avatarNameTitle'>
            <EmployeeAvatar employee={employee} avatarWidth={60}/>
            <p>{employee.displayName}</p>
            <p>{employee.jobTitle}</p>
        </div>
        <div className='coreValues'>
            {evaluationFormConfig.coreValuesConfigurations.map(c => {
                let initialBaseOption = evaluationFormConfig.coreValueInitialOption
                if (currentEvaluationValues[c.name] !== null) {
                    console.log('hwerjkwherkjwerhjk')
                    initialBaseOption = evaluationFormConfig.coreValueBaseOptions.filter(baseC => {
                        return baseC.value === currentEvaluationValues[c.name]
                    })[0]
                }
                return <div className='coreValue'>
                    <p>{c.display}</p>
                    <ToggleButton 
                        locked={formLocked()}
                        options={evaluationFormConfig.coreValueBaseOptions}
                        onChange={handleChange}
                        name={c.name}
                        value={currentEvaluationValues[c.name]}
                        initialOption={{ ...initialBaseOption }}
                    />
                </div>
            })}
        </div>
        <div className='gwc'>
            {evaluationFormConfig.gwcConfigurations.map(c => {
                let initialBaseOption = evaluationFormConfig.gwcBaseInitialOption
                if (currentEvaluationValues[c.name] !== null) {
                    initialBaseOption = evaluationFormConfig.gwcBaseOptions.filter(baseC => {
                        return baseC.value === currentEvaluationValues[c.name]
                    })[0]
                }
                return <ToggleButton 
                    locked={formLocked()}
                    options={evaluationFormConfig.gwcBaseOptions.map(o => ({ ...o, display: c.display }))} 
                    onChange={handleChange} 
                    name={c.name} 
                    value={currentEvaluationValues[c.name]}
                    initialOption={{ ...initialBaseOption, display: c.display }}
                />
            })}
        </div>
        <div className='buttons'>
            {currentEvaluationValues.evaluation_quarter === findLastQuarter() ? <>
                {manager.id === currentEvaluationValues.evaluation_evaluator_id ? <>
                    {currentQuarterEvaluationExists(employee) ? <>
                        {!editToggled ? <>
                            <button onClick={toggleEditEvaluation}>Edit</button>
                        </> : <>
                            <button onClick={handleUpdateEvaluation}>Update</button>
                            <button onClick={cancelEditValues}>Cancel</button>
                        </>}
                    </> : <>
                        <button onClick={handleSubmitEvaluation}>Submit</button>
                    </>}
                </> : <></>}
            </> : <></>}
        </div>
    </div>)
}

export default EvaluationForm

const { BlobServiceClient } = require("@azure/storage-blob");
const blobSasUrl =
  "https://tbconnectstorage.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-01-30T08:03:13Z&st=2024-01-30T00:03:13Z&spr=https&sig=kTFgjI0cbH%2BcDGLdPTGMljV8hlvklkttnlps5wyHxNw%3D";
const blobServiceClient = new BlobServiceClient(blobSasUrl);
const containerName = "siteimages";
const containerClient = blobServiceClient.getContainerClient(containerName);

export function generateEmailHtml(
  list,
  report,
  ccMyself,
  username,
  managerEmail
) {
  console.log(ccMyself);

  let updatedRows = list.map((row) => {
    if (row?.attachments.length > 0) {
      let fileType =
        row.attachments[0].name.split(".")[
          row.attachments[0].name.split(".").length - 1
        ];
      // generate url for attachment in database
      let url = `${row.attachments[0].name.split(".")[0]} ${new Date()
        .toLocaleDateString()
        .replaceAll("/", "_")}.${fileType}`;
      // save attachments to database
      const blockBlobClient = containerClient.getBlockBlobClient(url);
      blockBlobClient.uploadBrowserData(row.attachments[0]);
      // add link to attachment to row
      return {
        ...row,
        attachments: [
          {
            filename: row.attachments[0].name,
            link: blockBlobClient.url,
          },
        ],
      };
    }
    return row;
  });

  // generate html table from rows
  let table = `<table style="border-collapse: collapse; border: 1px solid #d3d3d3; width: 100%; text-align: left;">
          <thead>
              <tr>
                  <th style="border: 1px solid #d3d3d3; padding: 5px;">Date</th>
                  <th style="border: 1px solid #d3d3d3; padding: 5px;">Description/Who/Where</th>
                  <th style="border: 1px solid #d3d3d3; padding: 5px;">Project</th>
                  <th style="border: 1px solid #d3d3d3; padding: 5px;">Total</th>
                  <th style="border: 1px solid #d3d3d3; padding: 5px;">Attachments</th>
              </tr>
          </thead>
          <tbody>`;
  updatedRows.forEach((row) => {
    // set date of month + 1 to fix bug where date is off by 1 day
    const currentDate = new Date(row.date);
    currentDate.setDate(currentDate.getDate() + 1);
    const formattedDate = currentDate.toLocaleDateString();
    table += `<tr>
              <td style="border: 1px solid #d3d3d3; padding: 5px;">${formattedDate}</td>
              <td style="border: 1px solid #d3d3d3; padding: 5px;">${
                row.description
              }</td>
              <td style="border: 1px solid #d3d3d3; padding: 5px;">${
                row.project
              }</td>
              <td style="border: 1px solid #d3d3d3; padding: 5px;">$${
                row.total
              }</td>
              <td style="border: 1px solid #d3d3d3; padding: 5px;">${
                row.attachments.length > 0
                  ? ` <a href="${row.attachments[0]?.link}">
                    ${row.attachments[0]?.filename}
                  </a>`
                  : ""
              }</td>
          </tr>`;
  });
  table += `</tbody>
          </table>`;

  let condoshield =
    report.employee?.toLowerCase()?.includes("condo") ||
    report.department?.toLowerCase()?.includes("condo") ||
    report.position?.toLowerCase()?.includes("condo") ||
    username?.toLowerCase()?.includes("condo");

  let toRecipients = condoshield
    ? [
        {
          emailAddress: {
            address: "nicole.heins@condoshield.org",
          },
        },
        {
          emailAddress: {
            address: "kealern@transblue.org",
          },
        },
        {
          emailAddress: {
            address: "david.vetter@evergreenbrands.com",
          },
        },
      ]
    : [
        {
          emailAddress: {
            address: "david.vetter@evergreenbrands.com",
          },
        },
        {
          emailAddress: {
            address: "kealern@transblue.org",
          },
        },
      ];

  let bccRecipients = [
    {
      emailAddress: {
        address: "carters@transblue.org",
      },
    },
    {
      emailAddress: {
        address: "jim.wescott@evergreenbrands.com",
      },
    },
  ];

  let email = {
    message: {
      subject: "New Expense Report submitted",
      body: {
        contentType: "HTML",
        content: `Hello, <br /><br />
                          ${
                            report.employee
                          } has submitted an expense report.<br /><br />
                          Department: ${report.department}<br />
                          Position: ${report.position}<br />
                          Manager: ${report.manager}<br /><br />
                          ${table} <br />
                          <b>Total</b>: $${
                            parseInt(report.total)
                              ? Number(report.total).toFixed(2)
                              : 0
                          }<br /><br />
                          Best wishes,<br />
                          Transblue team
                      `,
      },
      toRecipients: toRecipients,
      bccRecipients: bccRecipients,
    },
  };

  if (ccMyself) {
    email.message.ccRecipients = [
      {
        emailAddress: {
          address: username,
        },
      },
      {
        emailAddress: {
          address: managerEmail,
        },
      },
    ];
  } else {
    email.message.ccRecipients = [
      {
        emailAddress: {
          address: managerEmail,
        },
      },
    ];
  }

  return email;
}

export function isValid(report, setErrors) {
  console.log(report.department);
  let errorObject = {
    employee: false,
    department: false,
    position: false,
    manager: false,
  };
  Object.keys(errorObject).forEach((key) => {
    if (report[key] === "" || !report[key]) {
      errorObject[key] = true;
    }
  });

  setErrors(errorObject);
  return Object.values(errorObject).every((value) => value === false);
}

export const itemTemplate = {
  date: "",
  description: "",
  project: "",
  total: "",
  attachments: [],
};

export const initialValues = {
  employee: localStorage.getItem("name") || "",
  position: localStorage.getItem("position") || "",
  total: 0,
};

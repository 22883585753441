import React, { useEffect, useState } from "react";
import ExpenseReport from "./expenseReport";
import MobileExpenseReport from "./mobileExpenseReport";

function ExpenseReportWrapper() {
  const [screenSize, setScreenSize] = useState("desktop");

  useEffect(() => {
    const width = window.innerWidth;
    if (width < 768) {
      setScreenSize("mobile");
    }
  }, []);

  return screenSize === "desktop" ? <ExpenseReport /> : <MobileExpenseReport />;
}

export default ExpenseReportWrapper;

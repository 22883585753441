import React from "react";
import "./assets/index.css";
import EmployeeContactLinks from "../employee-contact-links";
import EmployeeAvatar from "../../../../components/employee-avatar";
import { Link } from "react-router-dom";

const EmployeeCard = (props) => {
  const { employee, includeDepartment, includeLinks, cardWidth, cardMargin } =
    props;

  let departmentSlug;
  if (employee.department) departmentSlug = employee.department.toLowerCase();

  const mediumFontSize = `${cardWidth * 0.1}px`;
  const smallFontSize = `${cardWidth * 0.064}px`;

  return (
    <div
      className="employeeCard"
      style={{
        width: `${cardWidth}px`,
        margin: `${cardMargin ? cardMargin : "0"}px`,
      }}
    >
      <div className="avatarNameTitle">
        <EmployeeAvatar employee={employee} avatarWidth={cardWidth * 0.4} />
        <p
          className="displayName"
          data-testid="employeeCard-displayName"
          style={{ fontSize: mediumFontSize }}
        >
          {employee.displayName}
        </p>
        <p
          className=""
          style={{ fontSize: smallFontSize, textAlign: "center" }}
        >
          {employee.jobTitle}
        </p>
      </div>
      {includeDepartment ? (
        <>
          <Link to={`/directory/departments/${departmentSlug}`}>
            <p className="department" data-testid="employeeCard-department">
              {employee.department}
            </p>
          </Link>
        </>
      ) : (
        <></>
      )}
      {includeLinks ? (
        <>
          <EmployeeContactLinks employee={employee} />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EmployeeCard;

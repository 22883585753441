import React, { useContext, useEffect, useState } from 'react'
import './assets/index.css'
import ReviewRequestCard from '../../../../components/review-request-card'
import ClubhouseService from '../../../../api/ClubhouseService'
import { useMsal } from '@azure/msal-react'
import { clubhouseFnsRequest } from '../../../../config/auth'
import { findRequestReviewStatus } from '../../../../utils/requests'
import { DirectoryContext } from '../../../../App'

const initialEvaluatedRequestReviews = {
    needsReview: [],
    approved: [],
    denied: []
}

const RequestReviews = (props) => {
    const directoryContext = useContext(DirectoryContext);
    const { employees } = directoryContext;
    const { manager } = props;
    const { instance } = useMsal()
    const [requests, setRequests] = useState([])
    const [selectedFilter, setSelectedFilter] = useState('Needs Review')
    const [evaluatedRequestReviews, setEvaluatedRequestReviews] = useState(initialEvaluatedRequestReviews)
    
    const evaluateRequestsToReview = (requests) => {
        const needsReview = []
        const approved = []
        const denied = []
        requests.forEach(request => {
            const requestReviewStatus = findRequestReviewStatus(request, manager.id)
            if (requestReviewStatus === 'Needs Review') needsReview.push(request)
            if (requestReviewStatus === 'Approved') approved.push(request)
            if (requestReviewStatus === 'Denied') denied.push(request)
        })
        return {
            needsReview,
            approved,
            denied
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const clubhouseFnsTokenResponse = await instance.acquireTokenSilent({
                    ...clubhouseFnsRequest
                })
                const clubhouseFnsToken = clubhouseFnsTokenResponse.accessToken
                const requestsToApprove = await ClubhouseService.getRequests({ params: { asReviewer: true }, clubhouseFnsToken })
                setEvaluatedRequestReviews(evaluateRequestsToReview(requestsToApprove.data))
                setRequests(requestsToApprove.data)
            } catch (err) {
                console.log(err)
            }
        })()
    }, [])

    const reviewRequest = async (requestToApprove, approved) => {
        try {
            const clubhouseFnsTokenResponse = await instance.acquireTokenSilent({
                ...clubhouseFnsRequest
            })
            const clubhouseFnsToken = clubhouseFnsTokenResponse.accessToken
            const config = { 
                clubhouseFnsToken,
                requestToApprove: requestToApprove,
                params: { approved }
            }
            await ClubhouseService.updateRequest(config)
            // on success, update reuests locally
            const updatedWithManagerApproval = requests.map(r => {
                if (r.request_id === requestToApprove.request_id) {
                    return {
                        ...requestToApprove,
                        request_reviews: requestToApprove.request_reviews.map(review => {
                            if (review.request_review_reviewer_id === manager.id) {
                                return {
                                    ...review,
                                    request_review_reviewed: 1,
                                    request_review_approved: approved ? 1 : 0
                                }
                            } else return review
                        })
                    }
                } else return r
            })
            setEvaluatedRequestReviews(evaluateRequestsToReview(updatedWithManagerApproval))
        } catch (err) {
            throw err
        }
    }

    const handleSelectFilter = (e) => {
        setSelectedFilter(e.target.value)
    }

    return (<div className='requestReviews'>
        <div className='requestReviewsHeader'>
            <h3>Request Reviews</h3>
            <select onChange={handleSelectFilter}>
                <option value='Needs Review'>Needs Review</option>
                <option value='Approved'>Approved</option>
                <option value='Denied'>Denied</option>
            </select>
        </div>
        {selectedFilter === 'Needs Review' ? <>
            {evaluatedRequestReviews.needsReview.length > 0 ? <>
                {evaluatedRequestReviews.needsReview.map(request => <ReviewRequestCard reviewRequest={reviewRequest} request={request} manager={manager} />)}
            </> : <>You have no requests to review.</>}
        </> : selectedFilter === 'Approved' ? <>
            {evaluatedRequestReviews.approved.length > 0 ? <>
                {evaluatedRequestReviews.approved.map(request => <ReviewRequestCard reviewRequest={reviewRequest} request={request} manager={manager} />)}
            </> : <>You haven't approved any requests.</>}
        </> : <>
            {evaluatedRequestReviews.denied.length > 0 ? <>
                {evaluatedRequestReviews.denied.map(request => <ReviewRequestCard reviewRequest={reviewRequest} request={request} manager={manager} />)}
            </> : <>You haven't denied any requests.</>}
        </>}
    </div>)
}

export default RequestReviews

import {
  Avatar,
  Button,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
  Menu,
} from "@mui/material";

import { Box } from "@mui/system";
import React, { createRef, useState } from "react";
import Tree from "react-d3-tree";
import share from "../../images/share.png";
import Phone from "@mui/icons-material/Phone";
import { useScreenshot } from "use-react-screenshot";

// logo imports
import condoshield from "../../images/cs.png";
import tb from "../../images/transblue.png";
import evergreen from "../../images/evergreen.png";
import honey4hope from "../../images/honey4hope.png";
import mbridge from "../../images/mbridge.png";

const logos = {
  mbridge: mbridge,
  honey4hope: honey4hope,
  evergreen: evergreen,
  transblue: tb,
  condoshield: condoshield,
};

const colors = {
  mbridge: "#024690",
  honey4hope: "#F0B728",
  evergreen: "#EE6A22",
  transblue: "#0b57a4",
  condoshield: "#C9B06E",
};

const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps,
  admin,
}) => (
  <g>
    {/* `foreignObject` requires width & height to be explicitly set. */}
    <foreignObject {...foreignObjectProps}>
      <div
        onClick={toggleNode}
        style={{
          border: "1px solid rgba(0,0,0,.3)",
          borderRadius: "3px",
          backgroundColor: "white",
          textAlign: "center",
        }}
      >
        {nodeDatum.department && (
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              py: 1,
              // bgcolor: "rgba(0, 122, 255, .05)",
              bgcolor: nodeDatum?.attributes?.mail?.includes("condoshield")
                ? "#000"
                : `${
                    colors[
                      Object.keys(logos).find((c) => {
                        // Extract the domain from the email
                        const emailDomain = nodeDatum?.attributes?.mail
                          ?.toLowerCase()
                          ?.split("@")[1];
                        // Check if the domain includes the key
                        return emailDomain?.includes(c);
                      })
                    ]
                  }1A`,
              border: `1px solid ${
                colors[
                  Object.keys(logos).find((c) => {
                    // Extract the domain from the email
                    const emailDomain = nodeDatum?.attributes?.mail
                      ?.toLowerCase()
                      ?.split("@")[1];
                    // Check if the domain includes the key
                    return emailDomain?.includes(c);
                  })
                ]
              }`,
              // color: "rgb(0, 122, 255)",
              fontWeight: 600,
              letterSpacing: ".05em",
              borderRadius: "3px 3px 0px 0px",

              color:
                colors[
                  Object.keys(logos).find((c) => {
                    // Extract the domain from the email
                    const emailDomain = nodeDatum?.attributes?.mail
                      ?.toLowerCase()
                      ?.split("@")[1];
                    // Check if the domain includes the key
                    return emailDomain?.includes(c);
                  })
                ],
            }}
          >
            {nodeDatum?.department}
          </Typography>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <img
            style={{ width: "30px", margin: "5px 0px 0px 5px" }}
            src={
              logos[
                Object.keys(logos).find((c) => {
                  // Extract the domain from the email
                  const emailDomain = nodeDatum?.attributes?.mail
                    ?.toLowerCase()
                    ?.split("@")[1];
                  // Check if the domain includes the key
                  return emailDomain?.includes(c);
                })
              ]
              // mbridge
            }
            alt=""
          />
          <Tooltip title="View Profile">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                admin
                  ? window.open(nodeDatum.attributes.link, "_blank")
                  : window.open(nodeDatum.attributes.nonAdminLink, "_blank");
              }}
              color="primary"
            >
              <img src={share} alt="" style={{ width: "15px" }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ px: 2, pb: 2 }}>
          <Avatar src={nodeDatum.attributes?.avatar} sx={{ m: "0 auto" }} />
          <h3 style={{ textAlign: "center", marginBottom: "0px" }}>
            {nodeDatum.name}
          </h3>
          <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
            {nodeDatum.attributes?.title}
          </Typography>
          {nodeDatum.attributes?.phone && (
            <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
              <Phone
                sx={{
                  verticalAlign: "middle",
                  fontSize: "12px",
                  color: "rgba(0,0,0,.6)",
                  mr: 1,
                }}
              />{" "}
              {nodeDatum.attributes?.phone}
            </Typography>
          )}
          {nodeDatum.attributes?.totalReports !== 0 && (
            <Typography
              variant="caption"
              sx={{
                color: "rgb(0, 107, 214)",
                textAlign: "center",
                fontWeight: 600,
                mt: 2,
              }}
            >
              {nodeDatum.attributes?.totalReports} Direct Reports
            </Typography>
          )}
        </Box>
      </div>
    </foreignObject>
  </g>
);

function D3({ tree, admin }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const nodeSize = { x: 200, y: 300 };
  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: -100,
    y: -100,
  };

  const ref = createRef();
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const download = (
    image,
    {
      name = `Org Chart ${new Date().toLocaleString()}`,
      extension = "jpg",
    } = {}
  ) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = `${name}.${extension}`;
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  const screenWidth = window.innerWidth;

  return (
    <>
      <Button
        variant="contained"
        sx={{ textTransform: "capitalize" }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disableElevation
      >
        Download
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={downloadScreenshot}>Image / JPG</MenuItem>
      </Menu>
      <Box
        ref={ref}
        sx={{ border: anchorEl ? "1px solid red" : 0, height: "75vh" }}
      >
        <Tree
          data={tree}
          orientation="vertical"
          pathFunc={"step"}
          dimensions={{ height: 800, width: 1000 }}
          separation={{ siblings: 2, nonSiblings: 2 }}
          enableLegacyTransitions
          renderCustomNodeElement={(rd3tProps) =>
            renderForeignObjectNode({
              ...rd3tProps,
              foreignObjectProps,
              admin,
            })
          }
          depthFactor={400}
          hasInteractiveNodes
          initialDepth={1}
          centerNode={0}
          translate={{ x: screenWidth / 2 - 100, y: 200 }}
        />
      </Box>
    </>
  );
}

export default D3;

import { useMsal } from '@azure/msal-react'
import React, { useEffect } from 'react'
import { Route, useNavigate } from 'react-router-dom'

const AdminProtectedRoute = (props) => {
    const { instance, accounts } = useMsal()
    const navigate = useNavigate()
    useEffect(() => {
        (async () => {
            const activeAccount = await instance.getActiveAccount()
            if (!activeAccount.idTokenClaims.roles || !activeAccount.idTokenClaims.roles.includes('Employee.Write')) {
                navigate('/')
            }
        })()
    }, [])

    return {...props.children}
}

export default AdminProtectedRoute

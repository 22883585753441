import React, { useContext, useState } from 'react'
import './assets/index.css'
import ItemSelector from '../item-selector'
import { DirectoryContext } from '../../../../App'

const AddNewDepartmentForm = (props) => {
    const directoryContext = useContext(DirectoryContext);
    const { employees } = directoryContext;
    const { setNewDepartmentFormVisible, handleCreateNewDepartment } = props
    const [formValues, setFormValues] = useState({ name: '', headerId: '' })

    const handleOnChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value
        })
    }

    const handleSelectHead = (departmentHead) => {
        handleOnChange({
            target: {
                name: 'headId',
                value: departmentHead.id
            }
        })
    }

    return (<div className='addNewDepartmentForm'>
        <div className='departmentName'>
            <label>Department Name</label>
            <input value={formValues.name} name='name' onChange={handleOnChange} />
        </div>
        <div className='departmentHead'>
            <label>Department Head</label>
            <ItemSelector keyIndicator='displayName' initialItem={false} handleSelectItem={handleSelectHead} items={employees} />
        </div>
        <button onClick={() => handleCreateNewDepartment(formValues)}>Create</button>
        <button onClick={() => setNewDepartmentFormVisible(false)}>Cancel</button>
    </div>)
}

export default AddNewDepartmentForm

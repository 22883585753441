import { Cake } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DirectoryContext } from "../../App";

const months = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

function Notifications({ data, month }) {
  // console.log(month)
  const [birthdays, setBirthdays] = useState([]);
  const [anniversaries, setAnniversaries] = useState([]);

  const directoryContext = useContext(DirectoryContext);
  const [employees, setEmployees] = useState(directoryContext.employees);

  useEffect(() => {
    setEmployees(directoryContext.employees);
  }, [directoryContext.employees]);

  useEffect(() => {
    // get midnight on the first day of this month
    let first = new Date();
    first.setMonth(months[month]);
    first.setDate(1);
    first.setHours(0);
    first.setMinutes(0);
    first.setSeconds(0);
    first.setMilliseconds(0);
    first = first.getTime();

    // get 11:59 on the last day of this month
    let last = new Date();
    last.setMonth(months[month]);
    last.setMonth(last.getMonth() + 1);
    last.setDate(0);
    last.setHours(23);
    last.setMinutes(59);
    last.setSeconds(59);
    last.setMilliseconds(999);
    last = last.getTime();

    console.log(last);
    //2020-08-31T07:00:00.000Z

    let birthdayArr = employees.flatMap((entry) => {
      let birthday = new Date(entry.birthday);
      birthday.setFullYear(new Date().getFullYear());
      birthday = birthday.getTime();

      if (birthday >= first && birthday <= last) {
        return [entry];
      }

      return [];
    });

    setBirthdays(birthdayArr);

    let anniversaryArr = employees.flatMap((entry) => {
      let anniversary = new Date(entry.hireDate);
      let years = new Date().getFullYear() - anniversary.getFullYear();
      anniversary.setFullYear(new Date().getFullYear());
      anniversary = anniversary.getTime();

      if (anniversary >= first && anniversary <= last && years > 0) {
        return [
          {
            displayName: entry.displayName,
            years: years,
          },
        ];
      }

      return [];
    });

    console.log(anniversaryArr);
    setAnniversaries(anniversaryArr);
  }, [data, month]);

  return (
    <Box sx={{ px: 4, pt: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {" "}
        <Cake sx={{ color: "#ed6a22", verticalAlign: "middle", mb: 1 }} />{" "}
        Birthdays this month{" "}
      </Typography>
      {birthdays.map((birthday) => (
        <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>
          {birthday.displayName}
        </Typography>
      ))}
      <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>
        {" "}
        <Cake sx={{ color: "#ed6a22", verticalAlign: "middle", mb: 1 }} />{" "}
        Anniversaries this month{" "}
      </Typography>
      {anniversaries.map((anniversary) => (
        <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>
          {anniversary.displayName} - {anniversary.years} years
        </Typography>
      ))}
    </Box>
  );
}

export default Notifications;

import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "./assets/index.css";
import { Alert, Button, Dialog } from "@mui/material";
import ItemSelector from "../../../../../admin/components/item-selector";
import { idToEmployee } from "../../../../../../utils/employees";
import { departmentNameToObject } from "../../../../../../utils/departments";
import MSGraphService from "../../../../../../api/MSGraphService";
import { checkObjectEquality } from "../../../../../../utils/objects";
import { useMsal } from "@azure/msal-react";
import { Close } from "@mui/icons-material";
import { DirectoryContext } from "../../../../../../App";

const EmployeeEditor = (props) => {
  const directoryContext = useContext(DirectoryContext);
  const { employees, setEmployees, departments } = directoryContext;
  const { instance } = useMsal();
  const { employee } = props;
  const [editing, setEditing] = useState(false);
  const [employeeEditValues, setEmployeeEditValues] = useState(employee);
  const [reassignSelectorVisible, setReassignSelectorVisible] = useState(false);
  const [errorsOnSave, setErrorsOnSave] = useState([]);

  useEffect(() => {
    setEmployeeEditValues(employee);
    setEditing(false);
  }, [employee]);

  const toggleEditEmployee = () => {
    setReassignSelectorVisible(false);
    setEditing(!editing);
  };

  const handleUpdateEmployeeValues = (e) => {
    setEmployeeEditValues({
      ...employeeEditValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveUpdatedValues = async () => {
    // check if any changes were made
    if (checkObjectEquality(employee, employeeEditValues))
      return toggleEditEmployee();
    // gather employees that need to be updated in MS 365
    let directReportsInheritorId = null;
    if (employeeEditValues.directReportsInheritorId) {
      directReportsInheritorId = employeeEditValues.directReportsInheritorId;
    }
    delete employeeEditValues.directReportsInheritorId;
    const updatedManagerEmployees = [];
    const updateErrors = [];
    const employeeSyncedWithUpdates = employeeEditValues;

    if (directReportsInheritorId) {
      employeeEditValues.directReports.forEach((reportId) => {
        const editedDirectReport = employees.filter(
          (e) => e.id === reportId
        )[0];
        editedDirectReport.manager = directReportsInheritorId;
        updatedManagerEmployees.push(editedDirectReport);
      });
      employeeSyncedWithUpdates.directReports = [];
    }
    // update edited employees
    const claims = await instance.acquireTokenSilent({});
    const config = {
      token: claims.accessToken,
    };

    // edit direct reports managers
    const successfulReportsUpdates = [];
    for (let i = 0; i < updatedManagerEmployees.length; i++) {
      //edit manager of current employee's direct report
      if (updatedManagerEmployees[i].id !== employee.id) {
        config.id = updatedManagerEmployees[i].id;
        config.managerId = directReportsInheritorId;
        try {
          await MSGraphService.updateManagerById(config);
          successfulReportsUpdates.push(updatedManagerEmployees[i]);
        } catch (err) {
          const errorMessage = err.response.data.error.message;
          updateErrors.push(
            `Update manager of ${updatedManagerEmployees[i].displayName} unsuccessful. Error message: ${errorMessage}`
          );
          employeeSyncedWithUpdates.directReports.push(
            updatedManagerEmployees[i].id
          );
        }
      }
    }
    //update employee local to this componenet
    if (employee.manager !== employeeEditValues.manager) {
      config.id = employee.id;
      config.managerId = employeeEditValues.manager;
      try {
        await MSGraphService.updateManagerById(config);
      } catch (err) {
        const errorMessage = err.response.data.error.message;
        updateErrors.push(
          `Update manager of ${employeeEditValues.displayName} unsuccessful. Error message: ${errorMessage}`
        );
        employeeSyncedWithUpdates.manager = employee.manager;
      }
    }
    if (employee.department !== employeeEditValues.department) {
      config.id = employee.id;
      config.departmentName = employeeEditValues.department;
      try {
        await MSGraphService.updateEmployeeDepartmentById(config);
      } catch (err) {
        const errorMessage = err.response.data.error.message;
        updateErrors.push(
          `Update department of ${employeeEditValues.displayName} unsuccessful. Error message: ${errorMessage}`
        );
        employeeSyncedWithUpdates.department = employee.department;
      }
    }
    if (employee.displayName !== employeeEditValues.displayName) {
    }
    if (employee.mail !== employeeEditValues.mail) {
    }
    if (employee.jobTitle !== employeeEditValues.jobTitle) {
    }

    const successfulReportsUpdateLookup = {};
    successfulReportsUpdates.forEach((e) => {
      successfulReportsUpdateLookup[e.id] = e;
    });
    if (updateErrors.length > 0) {
      setErrorsOnSave(updateErrors);
    }
    setEmployees(
      employees.map((e) => {
        if (e.id === employee.id) return employeeSyncedWithUpdates;
        if (successfulReportsUpdateLookup[e.id])
          return successfulReportsUpdateLookup[e.id];
        if (e.id === directReportsInheritorId)
          return {
            ...e,
            directReports: successfulReportsUpdates.map((e) => e.id),
          };
        return e;
      })
    );
  };

  const handleSelectManager = (manager) => {
    handleUpdateEmployeeValues({
      target: {
        name: "manager",
        value: manager.id,
      },
    });
  };

  const handleSelectDepartment = (department) => {
    handleUpdateEmployeeValues({
      target: {
        name: "department",
        value: department.name,
      },
    });
  };

  const handleReassignDirectReports = (directReportsInheritor) => {
    if (directReportsInheritor.id !== employee.id) {
      handleUpdateEmployeeValues({
        target: {
          name: "directReportsInheritorId",
          value: directReportsInheritor.id,
        },
      });
    }
  };

  const toggleReassignSelector = () => {
    setReassignSelectorVisible(!reassignSelectorVisible);
  };

  if (!editing) {
    return (
      <div className="employeeEditor">
        <div className="left">
          <div className="employeeAttribute">
            {employee.displayName ? (
              <>
                <p>{employee.displayName}</p>
              </>
            ) : (
              <>
                <p className="warning">*DISPLAY NAME missing</p>
              </>
            )}
          </div>
          <div className="employeeAttribute">
            {employee.mail ? (
              <>
                <p>{employee.mail}</p>
              </>
            ) : (
              <>
                <p className="warning">*PRINCIPAL NAME missing</p>
              </>
            )}
          </div>
          <div className="employeeAttribute">
            {employee.jobTitle ? (
              <>
                <p>{employee.jobTitle}</p>
              </>
            ) : (
              <>
                <p className="warning">*JOB TITLE missing</p>
              </>
            )}
          </div>
        </div>
        <div className="middle">
          <div className="employeeAttribute">
            {employee.department ? (
              <>
                <p>{employee.department}</p>
              </>
            ) : (
              <>
                <p className="warning">*DEPARTMENT missing</p>
              </>
            )}
          </div>
          <div className="employeeAttribute">
            {employee.manager ? (
              <>
                <p>
                  Manager:{" "}
                  {
                    employees.filter((e) => employee.manager === e.id)[0]
                      .displayName
                  }
                </p>
              </>
            ) : employee.jobTitle === "CEO" ? (
              <>
                <p>Manager: N/A</p>
              </>
            ) : (
              <>
                <p className="warning">*MANAGER missing</p>
              </>
            )}
          </div>
          <div className="employeeAttribute">
            {employee.directReports?.length > 0 ? (
              <>
                <p>{`Direct Reports: ${employee.directReports?.length}`}</p>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="right">
          <Button variant="outlined" onClick={toggleEditEmployee}>
            edit
          </Button>
        </div>
        <Dialog open={errorsOnSave.length > 0}>
          <Close
            sx={{ marginLeft: "auto" }}
            onClick={() => setErrorsOnSave([])}
          >
            Close
          </Close>
          {errorsOnSave.map((error) => (
            <Alert severity="warning">{error}</Alert>
          ))}
        </Dialog>
      </div>
    );
  } else {
    return (
      <div className="employeeEditor edit">
        <div className="left">
          <h3>Edit MS365 Employee</h3>
          <div className="employeeAttribute">
            <label>Display Name</label>
            <p>{employeeEditValues.displayName}</p>
            {/* <input 
                        name='displayName'
                        value={employeeEditValues.displayName}
                        onChange={handleUpdateEmployeeValues}
                        type='text'
                    /> */}
          </div>
          <div className="employeeAttribute">
            <label>Principal Name</label>
            <p>{employeeEditValues.mail}</p>
            {/* <input 
                        name='mail'
                        value={employeeEditValues.mail}
                        onChange={handleUpdateEmployeeValues}
                        type='text'
                    /> */}
          </div>
          <div className="employeeAttribute">
            <label>Job Title</label>
            <p>{employeeEditValues.jobTitle || "N/A"}</p>
            {/* <input 
                        name='jobTitle'
                        value={employeeEditValues.jobTitle}
                        onChange={handleUpdateEmployeeValues}
                        type='text'
                    /> */}
          </div>
        </div>
        <div className="middle">
          <div className="employeeAttribute">
            <label>Department</label>
            <ItemSelector
              keyIndicator="name"
              items={departments}
              initialItem={departmentNameToObject(
                employeeEditValues.department,
                departments
              )}
              handleSelectItem={handleSelectDepartment}
            />
          </div>
          <div className="employeeAttribute">
            {employee.jobTitle !== "CEO" ? (
              <>
                <label>Manager</label>
                <ItemSelector
                  keyIndicator="displayName"
                  items={employees}
                  initialItem={idToEmployee(
                    employeeEditValues.manager,
                    employees
                  )}
                  handleSelectItem={handleSelectManager}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="employeeAttribute">
            <label>Direct Reports</label>
            <div className="directReportsContainer">
              {employee.directReports?.length === 0 ? <p>None</p> : <></>}
              {employeeEditValues.directReports?.ap((id) => {
                return (
                  <p style={{ marginRight: "20px" }}>
                    {idToEmployee(id, employees).displayName}
                  </p>
                );
              })}
            </div>
            {employee.directReports?.length > 0 ? (
              <>
                <Button onClick={toggleReassignSelector}>
                  {reassignSelectorVisible ? "Cancel Reassign" : "Reassign All"}
                </Button>
                {reassignSelectorVisible ? (
                  <>
                    <label>Reassign Direct Reports To:</label>
                    <ItemSelector
                      keyIndicator="displayName"
                      items={employees}
                      initialItem={null}
                      handleSelectItem={handleReassignDirectReports}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="right">
          <Button variant="outlined" onClick={handleSaveUpdatedValues}>
            save
          </Button>
          <Button variant="outlined" onClick={toggleEditEmployee}>
            cancel
          </Button>
        </div>
      </div>
    );
  }
};

export default EmployeeEditor;

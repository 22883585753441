import React, { useContext, useState } from 'react'
import Evaluations from './features/evaluations'
import ToolSelector from '../../components/tool-selector'
import Approvals from './features/approvals'
import { DirectoryContext } from '../../App'

const ManagerAdmin = (props) => {
    const { manager } = props
    const [selectedTool, setSelectedTool] = useState('approvals')
    const directoryContext = useContext(DirectoryContext);
    const { employees } = directoryContext;

    const devManager = {
        ...manager,
        directReports: [
            "8a2ed327-8325-4858-9114-cf42cce88453",
            "b6a9a15e-1e62-4a30-8762-581fb6114c25",
            "53d69010-788e-4678-8e6f-a64ee9a69483"
        ]
    }

    const toolSelectorConfig = {
        baseSlug: 'manager-admin',
        selectedTool,
        setSelectedTool,
        tools: [
            { 
                display: 'Approvals',
                name: 'approvals',
                slug: 'approvals',
                component: <Approvals
                    manager={manager}
                />
            },
            { 
                display: 'Evaluations',
                name: 'evaluations',
                slug: 'evaluations',
                component: <Evaluations 
                    // evaluator={process.env.NODE_ENV === 'production' ? manager : devManager}
                    manager={manager}
                />
            }
        ]
    }

    return (<div className='managerAdmin'>
        <h1>Manager Admin Panel</h1>
        {manager ? <>
            <ToolSelector
                config={toolSelectorConfig}
            />
            <div className='tools'>
                {toolSelectorConfig.tools.map(tool => {
                    if (selectedTool === tool.name) return tool.component
                    return <></>
                })}
            </div>
        </> : <>Loading...</>}
    </div>)
}

export default ManagerAdmin

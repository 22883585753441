import React, { useContext, useState } from 'react'
import { idToEmployee } from '../../../../utils/employees'
import TeamEvaluator from './components/team-evaluator'
import './assets/index.css'
import { DirectoryContext } from '../../../../App'

const Evaluations = (props) => {
    const directoryContext = useContext(DirectoryContext);
    const { employees } = directoryContext;
    const { manager } = props;
    const [managerOfTeam, setManagerOfTeam] = useState(manager)
    const [evaluatorOfTeam, setEvaluatorOfTeam] = useState(manager)
    
    const myReportsWhoAreManagers = () => {
        return manager.directReports.filter(report => {
            const reportReports = employees.filter(e => e.id === report)[0].directReports
            if (reportReports.length > 0) return true
            return false
        }).map(id => employees.filter(e => e.id === id)[0])
    }

    const handleSelectManagerOfTeam = (e) => {
        setManagerOfTeam(idToEmployee(e.target.value, employees))
    }

    const handleSelectEvaluatorOfTeam = (e) => {
        setEvaluatorOfTeam(idToEmployee(e.target.value, employees))
    }

    return (<div className='evaluations'>
        <div className='titleAndQuarterSelector'>
            <h3>Direct reports of: </h3>
            <select onChange={handleSelectManagerOfTeam}>
                <option value={manager.id}>{`${manager.displayName}`}</option>
                {myReportsWhoAreManagers().map(report => {
                    return <option value={report.id}>{`${report.displayName}`}</option>
                })}
            </select>
            <h3>Evaluated by: </h3>
            <select onChange={handleSelectEvaluatorOfTeam}>
                <option value={manager.id}>{`${manager.displayName}`}</option>
                {managerOfTeam.id !== manager.id ? <>
                    {myReportsWhoAreManagers().map(report => {
                        return <option value={report.id}>{`${report.displayName}`}</option>
                    })}
                </> : <></>}
            </select>
        </div>
        <TeamEvaluator manager={manager} evaluatorOfTeam={evaluatorOfTeam} managerOfTeam={managerOfTeam} />
    </div>)
}

export default Evaluations

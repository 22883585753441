import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { FormHelperText } from "@mui/material";

const departments = [
  "Condo Shield",
  "Honey4Hope",
  "mBridge",
  "Finance/IT/HR",
  "Sales/Marketing",
  "Operations/Maintenance",
];

function DepartmentSelect({ value, handleChange, variant, error }) {
  return (
    <FormControl error={error} variant={variant} size="small" fullWidth>
      <InputLabel id="department-label">Department *</InputLabel>
      <Select
        labelId="department-label"
        label="Department"
        name="department"
        onChange={handleChange}
        value={value}
      >
        {departments.map((department) => (
          <MenuItem key={department} value={department}>
            {department}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>This field is required</FormHelperText>}
    </FormControl>
  );
}

export default DepartmentSelect;

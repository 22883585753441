import React from "react";
import "./assets/index.css";
import { Typography } from "@mui/material";
import { Mail, Phone } from "@mui/icons-material";
// import CalendarIcon from '../../assets/CalendarIcon.png'

const EmployeeContactLinks = (props) => {
  const { employee } = props;
  return (
    <>
      <Typography variant="body2" sx={{ textAlign: "center" }}>
        <Phone sx={{ verticalAlign: "center", mr: 1, fontSize: "14px" }} />
        {employee.businessPhones?.[0]
          ? employee.businessPhones[0]
          : employee.mobilePhone
          ? employee.mobilePhone
          : employee.faxNumber
          ? employee.faxNumber
          : ""}
      </Typography>
      <Typography variant="body2" sx={{ textAlign: "center" }}>
        <Mail sx={{ verticalAlign: "center", mr: 1, fontSize: "14px" }} />
        {employee.mail}
      </Typography>
      {/* <a href={`mailto:${employee.mail}`} target="_blank">
        <img className="email" src={EmailIcon} />
      </a>
      <a
        href={`https://teams.microsoft.com/l/chat/0/0?users=${employee.mail}`}
        target="_blank"
      >
        <img className="teams" src={TeamsIcon} />
      </a>
      <a href={`tel:${employee.mobilePhone}`}>
        <img className="phone" src={PhoneIcon} />
      </a> */}
      {/* <a href={``}>
            <img className='calendar' src={CalendarIcon} />
        </a> */}
    </>
  );
};

export default EmployeeContactLinks;

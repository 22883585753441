import {
    Button,
    IconButton,
    Modal,
    Grid,
    Typography,
    TextField,
    MenuItem,
    InputLabel,
    FormControl,
  } from "@mui/material";
  import BroadcastOnPersonalIcon from '@mui/icons-material/BroadcastOnPersonal';
  import axios from "axios";
  import React from "react";
  import { useEffect } from "react";
  import { useState } from "react";
  import ButtonBox from "../../components/buttonBox/buttonBox";
  import FullCalendar from "@fullcalendar/react"; // must go before plugins
  import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
  import { Cake, Celebration } from "@mui/icons-material";
  import { Box } from "@mui/system";
  import { PersonAdd } from "@mui/icons-material";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
  import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
  import { v4 as uuidv4 } from "uuid";
  import { format } from "date-fns";
  import CheckIcon from "@mui/icons-material/Check";
  import ToggleButton from "@mui/material/ToggleButton";
  import Select from "@mui/material/Select";
  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "90%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    boxShadow: 24,
  };
  const colors = [
    "#ff3366",
    "#3399ff",
    "#00cc00",
    "#33000",
    "#ff6600",
    "#9966ff",
    "#0066cc",
    "#990099",
    "#006600",
    "#ffcc00",
  ];
  const XLSX = require("xlsx");
  
  function Calendar() {
    const [open, setOpen] = useState(false);
    const [events, setEvents] = useState([]);
    const [newBirthday, setNewBirthday] = useState({
      birthday: "",
      anniversary: "",
      startYear: "",
    });
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    const start = new Date(year, month, 1).getTime();
    const end = new Date(year, month + 1, 0).getTime();
  
    const [thisMonthBirthdays, setThisMonthBirthdays] = useState([]);
    const [thisMonthAnniversaries, setThisMonthAnniversaries] = useState([]);
    const [idUser, setIdUser] = useState("");
    const [openBirthdayDelete, setOpenBirthdayDelete] = useState(false);
    const [openAnniversaryDelete, setOpenAnniversaryDelete] = useState(false);
    const [showUserInfo, setShowUserInfo] = useState({});
    const [showAnniversary, setShowAnniversary] = useState("");
    const [showBirthday, setShowBirthday] = useState("");
    const [newPost, setNewPost] = useState("");
    const [disable, setDisable] = useState(false);
    const [saveStartYear, setSaveStartYear] = useState([]);
    const [isToggled, setIsToggled] = useState(false);
    const [companyName, setCompanyName] = useState('');
    
    useEffect(() => {
        axios.get("https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=birthdays")
            .then((res) => {
            let birthdays = res.data.map((contact) => {
                return {
                    id: contact.id,
                    title: `${contact.name} Birthday `,
                    date: `${new Date().getFullYear()}-${contact.birthday}`,
                    backgroundColor: "#0b56a4",
                    borderColor: "#0b56a4",
                };
            });
            let currentBirthdays = res.data.filter(
                (contact) =>
                new Date(`${year}-${contact.birthday}`).getTime() > start &&
                new Date(`${year}-${contact.birthday}`).getTime() < end
            );
            console.log(currentBirthdays)
            let filter = res.data
                .filter((contact) => new Date().getFullYear() >= contact.startYear)
                .map((contact) => {
                return {
                    id: contact.id,
                    startYear: contact.startYear,
                };
                });
    
            let anniversaries = res.data
                .filter((contact) => new Date().getFullYear() >= contact.startYear)
                .map((contact) => {
                    return {
                    id: contact.id,
                    title: `${contact.name} Anniversary`,
                    date: `${new Date().getFullYear()}-${contact.anniversary}`,
                    backgroundColor: "#ed6a22",
                    borderColor: "#ed6a22",
                    };
                });
            let currentAnniversaries = res.data.filter(
                (contact) =>
                new Date(`${year}-${contact.anniversary}`).getTime() > start &&
                new Date(`${year}-${contact.anniversary}`).getTime() < end &&
                year > contact.startYear
            );
            // console.log(currentAnniversaries);
    
            setThisMonthBirthdays(currentBirthdays);
            setThisMonthAnniversaries(currentAnniversaries);
            setEvents([...anniversaries, ...birthdays]);
            setSaveStartYear(filter);
            });
    
        // axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/delete?containerId=birthdays&id=e2f54e4f-2b9c-4a1c-957a-270b5e5dfce0`).then(() => console.log('se elimino correctamente'));
    }, [newPost]);
  
    function saveVacation() {
    let formatedBirth = format(showBirthday, "MM-dd");
    let formatedAnniversary = ""
    let formatedStartYear = ""
    if(showAnniversary !== null){
        formatedAnniversary = format(showAnniversary, "MM-dd");
        formatedStartYear = Number(format(showAnniversary, "yyyy"))
    }
    setNewBirthday({
        ...newBirthday,
        birthday: formatedBirth,
        anniversary: formatedAnniversary,
        startYear: formatedStartYear,
        companyName: companyName,
    });
    let newBirthdayObject = {
        ...newBirthday,
        birthday: formatedBirth,
        anniversary: formatedAnniversary,
        startYear: formatedStartYear,
        companyName: companyName,
    };
    axios
        .post(
        `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/save?containerId=birthdays`,
        newBirthdayObject
        )
        .then((res) => {
        setEvents(
            [
            ...events,
            {
                ...newBirthday,
                id: uuidv4(),
            },
            ].map((event, index) => {
            return {
                ...event,
                backgroundColor: colors[colors.length % index],
                borderColor: colors[colors.length % index],
            };
            })
        );

        setNewPost(uuidv4());
        });

    setOpen(false);
    }
    function saveChanges() {
    let newBirtdayDate = format(showBirthday, "MM-dd");
    axios.post(
        `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/update?containerId=birthdays&id=${idUser}`,
        { name: showUserInfo.name, birthday: newBirtdayDate }
        )
        .then((res) => setNewPost(uuidv4()));
    setOpenBirthdayDelete(false);
    }
    function saveAnniversaryChanges() {
    let newBirth = format(showAnniversary, "MM-dd-yyyy");
    let startYear = newBirth.slice(6);
    // console.log(startYear);
    setNewBirthday({
        ...newBirthday,
        anniversary: newBirth.slice(0, -5),
        startYear: Number(startYear),
    });
    axios.post(`https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/update?containerId=birthdays&id=${idUser}`,
        {
            name: showUserInfo.name,
            anniversary: newBirth.slice(0, -5),
            startYear: newBirthday.startYear,
        }
        )
        .then((res) => setNewPost(uuidv4()));

    setOpenAnniversaryDelete(false);
    }

    function deleteVacation(id) {
    let newEvents = events.filter((data) => data.id !== id);
    setEvents(newEvents);
    axios
        .post(
        `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/delete?containerId=birthdays&id=${id}`
        )
        .then((res) => setNewPost(uuidv4()));
    setOpenAnniversaryDelete(false);
    setOpenBirthdayDelete(false);
    }
    function handleChange(e) {
    setNewBirthday({
        ...newBirthday,
        [e.target.id]: e.target.value,
    });
    }
    function handleEditChange(e) {
    setShowUserInfo({
        ...showUserInfo,
        [e.target.id]: e.target.value,
    });
    }
    // console.log(saveStartYear)
    useEffect(() => {
    if(isToggled){
        setShowAnniversary(null)
        setDisable(true)
    }else{
        setDisable(false)
    }
    }, [isToggled])
    

    return (
    <>
        <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={{ ...style, maxWidth: "500px", p: 3 }}>
            <Typography variant="h5">Add Vacation Time</Typography>

            <TextField
            value={newBirthday.name || ""}
            fullWidth
            size="small"
            onChange={handleChange}
            sx={{ my: 2 }}
            label="User Name"
            id="name"
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                inputFormat="MM-dd-yyyy"
                label="Birthday Date"
                sx={{ mb: 3 }}
                
                value={showBirthday || null}
                renderInput={(params) => (
                <TextField {...params} size="small" fullWidth sx={{ mb: 2 }} />
                )}
                onChange={(newValue) => {
                // setNewBirthday({
                //   ...newBirthday,
                //   birthday: format(newValue, "MM-dd"),
                // });
                setShowBirthday(newValue);
                // console.log(showBirthday);
                }}
            />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                inputFormat="MM/dd/yyyy"
                label="Anniversary Date"
                sx={{ mb: 3 }}
                value={showAnniversary || null}
                disabled={disable}
                renderInput={(params) => (
                <TextField {...params} size="small" fullWidth sx={{ mb: 2 }} />
                )}
                onChange={(newValue) => {
                // setNewBirthday({
                //   ...newBirthday,
                //   anniversary: format(newValue, "MM-dd"),
                //   startYear: Number(format(newValue, "yyyy")),
                // });
                setShowAnniversary(newValue);
                }}
            />
            </LocalizationProvider>
        <Box sx={{display:'flex', justifyContent:'space-between', mt:1}}>
            <span style={{marginTop:'5px'}}>Are you a remote employee?</span>
            <ToggleButton
            value="check"
            selected={isToggled}
            size="small"
            color="primary"
            onChange={() => {
                setIsToggled(!isToggled);
                
            }}
            >
            <CheckIcon />
            </ToggleButton>
            </Box>
            {isToggled && (
            <FormControl fullWidth size="small" sx={{mt:3}}>
                <InputLabel id="select-label">Company Name</InputLabel>
                <Select
                labelId="select-label"
                value={companyName || "DOXA"}
                fullWidth
                onChange={(e) => setCompanyName(e.target.value)}
                sx={{ mb: 2 }}
                label="Company Name"
                id="company"
                >
                <MenuItem value={"DOXA"}>DOXA</MenuItem>
                <MenuItem value={"The Functionary"}>The Functionary</MenuItem>
                <MenuItem value={"CapaWorks"}>CapaWorks</MenuItem>
                </Select>
            </FormControl>
            )}
            <ButtonBox>
            <Button
                sx={{ fontSize: "10px", mb: 2, mt: 3 }}
                color="success"
                variant="contained"
                onClick={()=>{
                try{
                    saveVacation()

                }catch(e){
                    if (e instanceof TypeError) {
                    // sentencias para manejar excepciones TypeError
                } else if (e instanceof RangeError) {
                    // sentencias para manejar excepciones RangeError
                    alert('Type a valid date')
                } else if (e instanceof EvalError) {
                    // sentencias para manejar excepciones EvalError
                }
                }
                setIsToggled(false)
                }}
                // disabled={disable}
            >
                save user
            </Button>
            </ButtonBox>
        </Box>
        </Modal>

        <ButtonBox>
            <IconButton
            sx={{ fontSize: "10px", mb: 2, bgcolor: "green", color: "white" }}
            //endIcon={<AddCircle />}
            color="success"
            variant="contained"
            onClick={() => {
                setNewBirthday({
                ...newBirthday,
                name: "",
                birthday: "",
                anniversary: "",
                });
                setShowBirthday("");
                setShowAnniversary("");
                setOpen(true);
            }}
            >
            <PersonAdd />
            </IconButton>
        </ButtonBox>
        <Grid container>
            <Grid item xs={9} p={2}>
            <Box sx={{ bgcolor: "white", p: 2 }}>
                <FullCalendar
                height="100vh"
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                events={events}
                eventClick={(eventInfo) => {
                    let AnniversaryFormat = "";
                    setIdUser(eventInfo.event.id);
                    let newYear = saveStartYear.filter(
                    (data) => data.id === eventInfo.event.id
                    );
                    let name = "";
                    let anniversaryDate = "";
                    if (eventInfo.event.title.includes("Anniversary")) {
                    name = eventInfo.event.title.slice(0, -12);
                    anniversaryDate = `${
                        newYear[0].startYear
                    }-${eventInfo.event.startStr.slice(5)}`;
                    AnniversaryFormat = anniversaryDate.replaceAll("-", "/");
                    setShowAnniversary(AnniversaryFormat);
                    setOpenAnniversaryDelete(true);
                    } else {
                    name = eventInfo.event.title.slice(0, -9);
                    setOpenBirthdayDelete(true);
                    }
                    let BirthdayFormat = eventInfo.event.startStr
                    .slice(4)
                    .replaceAll("-", "/");

                    let newBirthdayFormat = `${newYear[0]?.startYear}${BirthdayFormat}`;

                    setShowUserInfo({ name: name, birthday: newBirthdayFormat });
                    setNewBirthday({
                    ...newBirthday,
                    birthday: newBirthdayFormat,
                    anniversary: AnniversaryFormat,
                    startYear: newYear[0].startYear,
                    });
                }}
                />
            </Box>
            </Grid>
            <Grid item xs={3} pt={2}>
            <Box sx={{ bgcolor: "white", height: "100vh", p: 2 }}>
                {/* <input type='file' onChange={readFileUpload} />     */}
                <Typography variant="h6" sx={{ mb: 2 }}>
                <Cake sx={{ verticalAlign: "middle", color: "#ed6a22" }} />{" "}
                Birthdays This Month
                </Typography>

                {thisMonthBirthdays.length > 0 ? (
                thisMonthBirthdays.map((contact) => (
                    <Grid container key={contact.name}>
                    <Grid item xs={9}>
                        {contact.companyName ? <Typography variant="body2">{contact.name} {<BroadcastOnPersonalIcon fontSize="medium" sx={{mb:-0.5}}/>}</Typography> : <Typography variant="body2">{contact.name}</Typography>}
                        
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        {contact.birthday}
                        </Typography>
                    </Grid>
                    </Grid>
                ))
                ) : (
                <Typography
                    variant="subtitle2"
                    sx={{ width: "100%", textAlign: "center" }}
                >
                    No birthdays this month
                </Typography>
                )}

                <Typography variant="h6" sx={{ mb: 2, mt: 3 }}>
                <Celebration
                    sx={{ verticalAlign: "middle", color: "#ed6a22" }}
                />{" "}
                Anniversaries This Month
                </Typography>

                {thisMonthAnniversaries.length > 0 ? (
                thisMonthAnniversaries.map((contact) => (
                    <Grid container key={contact.name}>
                    <Grid item xs={9}>
                        <Typography variant="body2">{contact.name}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        {year - contact.startYear} years
                        </Typography>
                    </Grid>
                    </Grid>
                ))
                ) : (
                <Typography
                    variant="subtitle2"
                    sx={{ width: "100%", textAlign: "center" }}
                >
                    No anniversaries this month
                </Typography>
                )}
            </Box>
            </Grid>
            <Modal
            open={openBirthdayDelete}
            onClose={() => setOpenBirthdayDelete(false)}
            >
            <Box sx={{ ...style, maxWidth: "500px", p: 3 }}>
                <Typography variant="h5">Add Birthday Time</Typography>

                <TextField
                value={showUserInfo.name || ""}
                fullWidth
                size="small"
                onChange={handleEditChange}
                sx={{ my: 2 }}
                label="User Name"
                id="name"
                />

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    inputFormat="MM/dd"
                    label="Birthday Date"
                    sx={{ mb: 3 }}
                    value={newBirthday.birthday}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    )}
                    onChange={(newValue) => {
                    // setNewBirthday({
                    //   ...newBirthday,
                    //   birthday: format(newValue, "MM-dd"),
                    // });
                    setShowBirthday(newValue);
                    }}
                />
                </LocalizationProvider>
                <ButtonBox>
                <Button
                    sx={{ fontSize: "10px", mb: 2, mt: 3, mr: 2 }}
                    color="primary"
                    variant="contained"
                    onClick={() => saveChanges()}
                >
                    Save changes
                </Button>
                <Button
                    sx={{ fontSize: "10px", mb: 2, mt: 3 }}
                    color="error"
                    variant="contained"
                    onClick={() => deleteVacation(idUser)}
                >
                    Delete
                </Button>
                </ButtonBox>
            </Box>
            </Modal>
            <Modal
            open={openAnniversaryDelete}
            onClose={() => setOpenAnniversaryDelete(false)}
            >
            <Box sx={{ ...style, maxWidth: "500px", p: 3 }}>
                <Typography variant="h5">Add Anniversary Time</Typography>

                <TextField
                value={showUserInfo.name || ""}
                fullWidth
                size="small"
                onChange={handleEditChange}
                sx={{ my: 2 }}
                label="User Name"
                id="name"
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    label="Anniversary Date"
                    sx={{ mb: 3 }}
                    value={showAnniversary}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    )}
                    onChange={(newValue) => {
                    // setNewBirthday({
                    //   ...newBirthday,
                    //   anniversary: format(newValue, "MM-dd"),
                    //   startYear: Number(format(newValue, "yyyy")),
                    // });
                    setShowAnniversary(newValue);
                    }}
                />
                </LocalizationProvider>

                <ButtonBox>
                <Button
                    sx={{ fontSize: "10px", mb: 2, mt: 3, mr: 2 }}
                    color="primary"
                    variant="contained"
                    onClick={() => saveAnniversaryChanges()}
                >
                    Save changes
                </Button>
                <Button
                    sx={{ fontSize: "10px", mb: 2, mt: 3 }}
                    color="error"
                    variant="contained"
                    onClick={() => deleteVacation(idUser)}
                >
                    Delete
                </Button>
                </ButtonBox>
            </Box>
            </Modal>
        </Grid>
    </>
    );
}

export default Calendar;

import { Avatar } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { emailToSlug } from '../../../../utils/string'
import './assets/index.css'

const EmployeeHover = (props) => {
    const { configuration } = props
    const navigate = useNavigate()


    return (<div className='employeeHover'>
        <Avatar 
            className='avatar'
            onClick={() => navigate(`/directory/employee/${emailToSlug(configuration.mail)}`)}
            src={configuration.image}
            sx={{ width: '100px', height: '100px', zIndex: '1' }}
            children={`${configuration.displayName.split(' ')[0][0]}${configuration.displayName.split(' ')[1][0]}`}
        />
        <div 
            className='employeeInfo'
            style={{ backgroundColor: 'white' }}
        >
            <div 
                className='avatarNameAndJobTitle'
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%' }}
            >
                <Avatar 
                    className='avatar'
                    onClick={() => navigate(`/directory/employee/${emailToSlug(configuration.mail)}`)}
                    src={configuration.image}
                    sx={{ width: '75px', height: '75px', zIndex: '0' }}
                    children={`${configuration.displayName.split(' ')[0][0]}${configuration.displayName.split(' ')[1][0]}`}
                />
                <div className='nameAndJobTitle'>
                    <p style={{ fontWeight: '600' }}>{configuration.displayName}</p>
                    <p style={{ fontSize: '14px' }}>{configuration.jobTitle}</p>
                </div>
            </div>
            <div 
                className='departmentAndDepartmentHead'
                style={{ height: '40%', display: 'flex', width: '100%' }}
            >
                <div className='department' style={{ display: 'flex', flexDirection: 'column', width: '50%', marginLeft: '10px' }}>
                    <p style={{  margin: '0' }}>Department:</p>
                    <Link to={`/directory/departments/${configuration.department.toLowerCase()}`}>
                        <p style={{ width: '100px', height: '50px', textAlign: 'center', margin: '0', fontSize: '14px' }}>{configuration.department}</p>
                    </Link>
                </div>
                {configuration.headOfDepartments.length > 0 ? <>
                    <div className='departmentHead' style={{ display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'center', margin: '0' }}>
                        <p style={{ margin: '0' }}>Head of:</p>
                        {configuration.headOfDepartments.map(department => {
                            return <Link to={`/directory/departments/${department.name.toLowerCase()}`}>
                                <p style={{ width: '100px', textAlign: 'center', margin: '0', fontSize: '14px', width: '100%' }}>{department.name}</p>
                            </Link>
                        })}
                    </div>
                </> : <></>}
            </div>
        </div>
    </div>)
}

export default EmployeeHover

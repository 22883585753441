export const findRequestStatus = (request) => {
    let noneDenied = true
    let allReviewed = true
    request.request_reviews.forEach(a => {
        if (a.request_review_reviewed) {
            if (!a.request_review_approved) {
                noneDenied = false
            }
        } else {
            allReviewed = false
        }
    })
    if (noneDenied) {
        if (allReviewed) return 'Approved'
        else return 'Under Review'
    } else return 'Denied'
}

export const findRequestReviewStatus = (request, managerId) => {
    let approved = false
    let denied = false
    request.request_reviews.forEach(a => {
        if (a.request_review_reviewer_id === managerId) {
            if (a.request_review_reviewed) {
                if (!a.request_review_approved) denied = true
                else approved = true
            }
        }
    })
    if (approved) return 'Approved'
    if (denied) return 'Denied'
    return 'Needs Review'
}
import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { Route, useNavigate } from "react-router-dom";

const ManagerAdminProtectedRoute = (props) => {
  const { currentEmployee } = props;
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      if (currentEmployee) {
        if (currentEmployee.directReports?.length < 1) {
          navigate("/");
        }
      } else navigate("/");
    }
  }, [currentEmployee]);

  return { ...props.children };
};

export default ManagerAdminProtectedRoute;

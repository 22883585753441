import { Paper } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import AddVacationModal from "./components/add-vacation-modal";
import VacationDetailsModal from "./components/vacation-details-modal";
import VacationHeader from "./components/vacation-header";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "90%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
};

function Vacation() {
  const [vacations, setVacations] = useState([]);
  const [vacationModalOpen, setVacationModalOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [idVacation, setIdVacation] = useState("");
  const [newPost, setNewPost] = useState("");
  const [showUserInfo, setShowUserInfo] = useState({
    name: "",
    details: "",
    company: "",
    start: "",
    end: "",
  });

  useEffect(() => {
    axios
      .get(
        "https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=vacation"
      )
      .then((res) => {
        if (res.data !== "No items found") {
          setVacations(
            res.data.map((event, index) => {
              return {
                ...event,
              };
            })
          );
        }
      });
  }, [newPost]);

  const selectVacationShowDetails = (eventInfo) => {
    setIdVacation(eventInfo.event.id);
    let newUserInfo = vacations.filter(
      (event) => event.id === eventInfo.event.id
    );
    setShowUserInfo({
      ...showUserInfo,
      name: newUserInfo[0].title,
      details: newUserInfo[0].details,
      company: newUserInfo[0].company,
      hours: newUserInfo[0].hours,
      start: eventInfo.event.start,
      end: eventInfo.event.end,
    });
    setOpenDelete(true);
  };

  return (
    <>
      <VacationHeader setVacationModalOpen={setVacationModalOpen} />
      <Paper sx={{ minWidth: "500px", maxWidth: "900px", p: 3 }}>
        <FullCalendar
          // height="100vh"
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={vacations}
          weekends={false}
          eventClick={selectVacationShowDetails}
        />
      </Paper>
      <AddVacationModal
        style={style}
        vacationModalOpen={vacationModalOpen}
        setVacationModalOpen={setVacationModalOpen}
        setNewPost={setNewPost}
        vacations={vacations}
        setVacations={setVacations}
      />
      <VacationDetailsModal
        style={style}
        newPost={newPost}
        vacations={vacations}
        setVacations={setVacations}
        idVacation={idVacation}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        showUserInfo={showUserInfo}
      />
    </>
  );
}

export default Vacation;

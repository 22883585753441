export const findLastQuarter = () => {
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4']
    const today = new Date()
    const month = today.getMonth()
    const year = today.getFullYear()
    const currentQuarterIdx = Math.ceil(((month+1)/12)*4) - 1
    let lastQuarterIdx = currentQuarterIdx - 1
    if (currentQuarterIdx === 0) lastQuarterIdx = quarters.length-1
    const lastQuarter = quarters[lastQuarterIdx]
    let lastQuarterYear = year
    if (currentQuarterIdx === 0) lastQuarterYear -= 1
    return `${lastQuarterYear}${lastQuarter}`
    // return '2022Q3'
}

export const parseQuarter = (quarterCode) => {
    const quarterAndYear = quarterCode.split('Q')
    const quarter = parseInt(quarterAndYear[0])
    const year = parseInt(quarterAndYear[1])
    return { year, quarter }
}
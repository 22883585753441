import React, { useContext, useState } from "react";

// mui imports
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// context
import { EventsContext } from "./newCalendar";

// local imports
import search from "../../utils/search";
import { CalendarMonth } from "@mui/icons-material";

function Search() {
  const eventsContext = useContext(EventsContext);
  const { calendarEvents } = eventsContext;
  console.log(calendarEvents);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);

  const handleSearch = () => {
    let array = search(searchTerm, calendarEvents);
    console.log(array);
    setSearchResults(array);
    setHasSearched(true);
  };

  const handleChange = (e) => {
    if (e.target.value === "") {
      setSearchResults([]);
    }
    setSearchTerm(e.target.value);
    setHasSearched(false);
  };

  return (
    <Box sx={{ pl: { xs: 0, md: 3 } }}>
      <TextField
        value={searchTerm}
        onChange={handleChange}
        fullWidth
        size="small"
        label="Search Users"
        onKeyDown={(e) => {
          if (e.key === "Enter") handleSearch();
        }}
        sx={{ mb: 3, mt: { xs: 0, md: 2 } }}
      />

      {searchResults.length === 0 && hasSearched && (
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          No results found. Please try another search
        </Typography>
      )}

      {searchResults.map((result) => (
        <Box sx={{ py: 2 }} key={result.id}>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ flexGrow: 1 }}>
              <CalendarMonth
                sx={{ color: "#004F98", verticalAlign: "middle", mr: 1 }}
                fontSize="small"
              />
              {result.employee}
            </Typography>

            {result.date && (
              <Typography variant="body2">
                {new Date(result.date).toLocaleDateString()}
              </Typography>
            )}
          </Box>
          <Typography variant="body2" sx={{ fontStyle: "italic", ml: "28px" }}>
            {result.type !== "Birthday / Anniversary"
              ? result.type
              : result.title.includes("Birthday")
              ? "Birthday"
              : "Anniversary"}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

export default Search;

import React, { useContext, useState } from "react";
import { DirectoryContext } from "../App";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormGroup,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Delete, Save } from "@mui/icons-material";
import axios from "axios";

const types = ["WFH", "Vacation", "Travelling"];

// do we need company for each entry?

const initialValues = {
  type: "Vacation", // for all
  employee: "", // for all
  created: "", // for all
  vacationDates: [], // for time off requests
  hours: "", // calculated total for time off requests
  birthday: "", // for birthday / anniversary
  anniversary: "", // for birthday / anniversary
  travelDates: [], // for travelling
  wfh: {
    recurring: true, // one time or recurring
    date: "", // only show if  frequency is one time,
    daysOfWeek: [], // only show if frequency is recurring
  },
};

const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

function CalendarEntry({ setOpen, addEntry }) {
  const directoryContext = useContext(DirectoryContext);
  const { employees } = directoryContext;
  console.log(employees);

  const [formValues, setFormValues] = useState(initialValues);

  const submit = () => {
    formValues.created = new Date().getTime();

    console.log(formValues);

    try {
      axios
        .post(
          `https://my-tb-cors.herokuapp.com/https://transblueaccount-fns.azurewebsites.net/api/save?containerId=calendar&databaseId=employees`,
          formValues
        )
        .then((res) => {
          console.log(res);
          addEntry(res.data);
          setFormValues(initialValues);
          setOpen(false);
        });
    } catch (err) {
      alert("Oops! There was an error. Please try again");
    }
  };

  const handleRecurringChange = (value) => {
    let arr = formValues.wfh.daysOfWeek;
    if (!arr.includes(value)) {
      arr.push(value);
    } else {
      let index = arr.indexOf(value);
      arr.splice(index, 1);
    }

    setFormValues({
      ...formValues,
      wfh: {
        ...formValues.wfh,
        daysOfWeek: arr,
      },
    });
  };

  const getDaysOfWeekBetweenDates = (startDate, endDate) => {
    // endDate.setDate(endDate.getDate() + 1);

    let start = new Date(startDate);
    let end = new Date(endDate);

    let hours = 0;

    while (start <= end) {
      console.log(start.getDay());

      // saturday = 6 and sunday = 0
      if (start.getDay() !== 6 && start.getDay() !== 0) {
        hours += 8;
      }

      start.setDate(start.getDate() + 1);
    }

    console.log(hours);

    return hours;
  };

  const handleDateChange = (newValue) => {
    // check if start and end dates are both declared
    if (newValue[0] && newValue[1]) {
      console.log(newValue[1].$d > newValue[0].$d);

      let startDate = new Date(newValue[0].$d);
      let endDate = new Date(newValue[1].$d);

      if (formValues.type === "Vacation") {
        let entry = {
          ...formValues,
          vacationDates: [startDate, endDate],
        };

        // calculate the number of hours in weekdays of time range
        let hours = getDaysOfWeekBetweenDates(startDate, endDate);

        entry = {
          ...entry,
          hours: hours,
        };

        console.log(entry);

        setFormValues(entry);
      } else if (formValues.type === "Travelling") {
        setFormValues({
          ...formValues,
          travelDates: [startDate, endDate],
        });
      }
    }
  };

  return (
    <>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Add Calendar Entry
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <Stack direction="column" spacing={2}>
        <Select
          value={formValues.type}
          onChange={(e) =>
            setFormValues({ ...formValues, type: e.target.value })
          }
          size="small"
          fullWidth
        >
          {types.map((type) => (
            <MenuItem value={type} key={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
        {employees.length > 0 ? (
          <Autocomplete
            options={employees.map((employee) => employee.displayName)}
            value={formValues.employee}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                label="Select Employee"
              />
            )}
            freeSolo
            onInputChange={(e, newValue) =>
              setFormValues({
                ...formValues,
                employee: newValue,
              })
            }
          />
        ) : (
          <Skeleton variant="text" sx={{ fontSize: "40px" }} />
        )}
        {(formValues.type === "Vacation" ||
          formValues.type === "Travelling") && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateRangePicker
              localeText={{ start: "Start", end: "End" }}
              size="small"
              onChange={(newValue) => handleDateChange(newValue)}
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
        )}

        {formValues.hours && formValues.type === "Vacation" && (
          <TextField
            value={formValues.hours}
            fullWidth
            size="small"
            onChange={(e) =>
              setFormValues({
                ...formValues,
                hours: e.target.value,
              })
            }
            type="number"
            label="Total Hours"
          />
        )}

        {formValues.type === "Birthday / Anniversary" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              slotProps={{
                textField: { size: "small", sx: { my: 2 } },
              }}
              onChange={(newValue) =>
                setFormValues({
                  ...formValues,
                  birthday: newValue.$d,
                })
              }
              label="Birthday"
            />
            <DatePicker
              slotProps={{
                textField: {
                  size: "small",
                  sx: { my: 2, ml: 2 },
                },
              }}
              onChange={(newValue) =>
                setFormValues({
                  ...formValues,
                  anniversary: newValue.$d,
                })
              }
              label="Anniversary"
            />
          </LocalizationProvider>
        )}

        {formValues.type === "WFH" && (
          <>
            <Stack direction="row" mt={2}>
              <Typography sx={{ mt: 1, mr: 3 }}>
                Is this recurring weekly?
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={() =>
                    setFormValues({
                      ...formValues,
                      wfh: {
                        ...formValues.wfh,
                        recurring: !formValues.wfh.recurring,
                      },
                    })
                  }
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio checked={formValues.wfh.recurring} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio checked={!formValues.wfh.recurring} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>

              {!formValues.wfh.recurring && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        size: "small",
                        sx: { my: 2 },
                      },
                    }}
                    onChange={(newValue) =>
                      setFormValues({
                        ...formValues,
                        wfh: {
                          ...formValues.wfh,
                          date: newValue.$d,
                        },
                      })
                    }
                    label="Date"
                  />
                </LocalizationProvider>
              )}
            </Stack>

            {formValues.wfh.recurring && (
              <FormGroup>
                {weekdays.map((day, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.wfh.daysOfWeek.includes(index + 1)}
                      />
                    }
                    label={day}
                    onChange={() => handleRecurringChange(index + 1)}
                  />
                ))}
              </FormGroup>
            )}
          </>
        )}
      </Stack>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          onClick={() => {
            setOpen(false);
            setFormValues(initialValues);
          }}
          variant="outlined"
          sx={{ fontSize: "11px", mr: 1 }}
          startIcon={<Delete />}
        >
          discard
        </Button>
        <Button
          onClick={submit}
          variant="contained"
          sx={{ fontSize: "11px" }}
          startIcon={<Save />}
          disableElevation
        >
          save entry
        </Button>
      </Box>
    </>
  );
}

export default CalendarEntry;

import React, { useEffect } from 'react'
import './assets/index.css'
import { useLocation, useNavigate } from 'react-router-dom'

const ToolSelector = (props) => {
    const { config } = props
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const splitPath = location.pathname.split('/')
        splitPath.shift()
        const toolSlugRef = {}
        config.tools.forEach(tool => toolSlugRef[tool.slug] = 1)
        if (
            splitPath.length < 2 || 
            splitPath[splitPath.length-2] !== config.baseSlug ||
            !toolSlugRef[splitPath[splitPath.length-1]]
        ) {
            navigate(`/${config.baseSlug}/${config.tools[0].slug}`)
        } else {
            config.setSelectedTool(config.tools.filter(tool => tool.slug === splitPath[splitPath.length-1])[0].name)
        }
    }, [location])

    return (<div className='toolSelector'>
        {config.tools.map(tool => {
            return <p
                className={config.selectedTool === tool.name ? 'selected' : ''}
                onClick={() => navigate(`${config.baseSlug}/${tool.slug}`)}
            >
                {tool.display}
            </p>

        })}
    </div>)
}

export default ToolSelector
